import React, { Component } from "react"
import Notifications from "shared/containers/Notifications"
import { Menu, MenuList, MenuItem, NotificationHolder } from "./styles"

class NavBar extends Component {
  handleClick = () => {
    window.location = "/logout"
  }

  render() {
    return (
      <Menu>
        <MenuList>
          <MenuItem to="/overview" yarnologo={"true"} />
          <MenuItem end to="/overview">
            Overview
          </MenuItem>
          <MenuItem to="/accounts">Accounts</MenuItem>
          <MenuItem to="/feedback">Feedback</MenuItem>
        </MenuList>
        <MenuList>
          <NotificationHolder>
            <Notifications allNotificationsPath="/notifications" />
          </NotificationHolder>
          <MenuItem to="/platform">Platform</MenuItem>
          <a href="/logout">Logout</a>
        </MenuList>
      </Menu>
    )
  }
}

export default NavBar
