import NavLink from "shared/components/NavLink"
import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

export const Menu = styled.nav`
  position: sticky;
  background: white;
  height: 7rem;
  top: 0;
  z-index: ${zIndex.menu};
  display: flex;
  justify-content: space-between;

  > ul > a,
  > a {
    font-family: ${props => props.theme.fonts.heading};
    color: ${props => props.theme.colors.primary};
    font-size: 1.4rem;
    line-height: 7rem;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 0 3rem;
    display: block;

    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
`

export const NotificationHolder = styled.div`
  padding-top: 1rem;
`

export const MenuList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
`

export const MenuItem = styled(NavLink)`
  ${props =>
    props.yarnologo &&
    `
    background-image: url('https://yarno.imgix.net/static/logos/yarno_green.png?h=40');
    background-size: 64px 20px;
    background-position: center center;
    background-repeat: no-repeat;
    min-width: 12rem;
`}

  ${props =>
    !props.yarnoLogo &&
    `&.active {
    color: ${props.theme.colors.secondary};
  }`}
`