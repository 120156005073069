import styled from "styled-components"
import { smPixels } from "mill/utils/breakpoints"

export const VideoContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  background: #EEEEEE;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  ${props => props.verticalVideo && props.verticalVideoResponsive === true && `
  @media screen and (max-width: ${smPixels}px) {
    padding-bottom: 0;
    height: auto;
    aspect-ratio: 9 / 14.5;
  }`}

  ${props => props.verticalVideo && props.verticalVideoResponsive === false && `
    padding-bottom: 0;
    height: auto;
    aspect-ratio: 9 / 14.5;
  `}
`
