import React, { Fragment } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { parseISO, format } from "date-fns"
import Link from "shared/components/Link"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import withAccount from "cartwright/components/WithAccount"
import CursorPagination from "shared/components/CursorPagination"
import useCursorPagination from "shared/hooks/useCursorPagination"
import FETCH_ACCOUNT_CONTENT_ACTIVITY from "cartwright/graphql/FetchAccountContentActivity"

const ActivityListing = styled(Link)`
  text-decoration: none;
  display: block;
  padding: 1.5rem 2rem;
  color: #535353;
  border-top: 2px solid #eee;

  small {
    display: block;
  }
  &:hover {
    background: #efefef;
    cursor: pointer;
  }
`

const ContentActivity = ({ contentActivity, accountId }) => {
  if (contentActivity.length == 0) {
    return (
      <div>
        <p>No content activity to display</p>
      </div>
    )
  }

  return (
    <Fragment>
      {contentActivity.map(activity => {
        const activityTime = format(parseISO(activity.createdAt), "PPPpp")
        const contentableLabel = activity.contentableLabel.toLowerCase()
        const activityUrl =
          contentableLabel == "question"
            ? `/accounts/${accountId}/questions/${activity.contentableId}/edit`
            : `#`

        switch (activity.activity) {
          case "created":
            return (
              <ActivityListing to={activityUrl} key={activity.id}>
                {activity.authorName} created a {contentableLabel}
                <small>{activityTime}</small>
              </ActivityListing>
            )
          case "updated":
            return (
              <ActivityListing to={activityUrl} key={activity.id}>
                {activity.authorName} updated a {contentableLabel}
                <small>{activityTime}</small>
              </ActivityListing>
            )
          case "published":
            return (
              <ActivityListing to={activityUrl} key={activity.id}>
                {activity.authorName} published a {contentableLabel}
                <small>{activityTime}</small>
              </ActivityListing>
            )
          case "unpublished":
            return (
              <ActivityListing to={activityUrl} key={activity.id}>
                {activity.authorName} unpublished a {contentableLabel}
                <small>{activityTime}</small>
              </ActivityListing>
            )
          case "comment_added":
            return (
              <ActivityListing to={activityUrl} key={activity.id}>
                {activity.authorName} added a comment to a {contentableLabel}
                <small>{activityTime}</small>
              </ActivityListing>
            )
        }
      })}
    </Fragment>
  )
}

const AccountContentActivity = ({ accountId }) => {
  const cursorPagination = useCursorPagination()
  const { data, loading, error } = useQuery(FETCH_ACCOUNT_CONTENT_ACTIVITY, {
    variables: {
      accountId: accountId
    }
  })

  const contentActivity = data?.account?.contentActivity?.edges?.map(
    e => e.node
  )
  const pageInfo = data?.account?.contentActivity?.pageInfo

  return (
    <Box marginTop="medium" marginBottom="medium">
      <Heading level={1}>Account content activity</Heading>

      {!loading && data && (
        <Box marginTop="medium" marginBottom="medium">
          <ContentActivity
            contentActivity={contentActivity}
            accountId={accountId}
          />
          <Box marginTop="medium">
            <CursorPagination {...pageInfo} />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default withAccount(AccountContentActivity)
