import styled from "styled-components"
import { sm, md } from "mill/utils/breakpoints"

export const ModalContainer = styled.div`
  flex: 1;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  @media ${md} {
    max-height: 80vh;
  }
`

export const Header = styled.div`
  border-bottom: 1px solid #d8d8d8;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  max-height: 5rem;

  h2 a,
  a:visited {
    margin-right: 1rem;
    display: block;
    font-size: 1.6rem;
    color: ${props => props.theme.colors.primary};

    @media ${sm} {
      margin-left: 1.2rem;
      display: inline-block;
    }
  }
`

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  overflow-y: hidden;

  > div {
    flex: 1;
    overflow-y: scroll;
    width: 100%;
    margin: 2px;
    &:nth-of-type(2) {
      margin-top: 2rem;
    }
  }

  @media ${md} {
    flex-direction: row;
    > div {
      overflow-y: scroll;
      max-height: calc(80vh - 5rem);
      &:nth-of-type(2) {
        margin-top: 2px;
        margin-left: 2rem;
      }
    }
  }
`

export const PaginationButtons = styled.div`
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  button {
    margin-top: 0;
    width: 10rem;

    &:first-of-type {
      margin-bottom: 0.5rem;
    }

    @media ${sm} {
      width: 12rem;
      &:first-of-type {
        margin-right: 1rem;
        margin-bottom: 0;
      }
    }
  }

  @media ${sm} {
    flex-direction: row;
  }
`
