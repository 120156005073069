import React from "react"
import styled from "styled-components"
import ExplanationVideo from "mill/containers/ExplanationVideo"
import { md } from "mill/utils/breakpoints"
import { allowedDomain as allowedVideoDomain } from "mill/hooks/useVideoEmbed"

export const LinkContainer = styled.div`
  align-items: center;
  display: flex;
  i {
    margin-top: 1rem;
    font-size: 2rem;
    color: #a0a0a0;
    position: relative;
    width: 24px;
  }

  a ins {
    text-decoration: none;
  }
  @media ${md} {
    align-items: flex-start;
    i {
      margin-top: 1rem;
    }
  }
`

export const Link = styled.div`
  padding-left: 20px;
  flex: 1;
  color: #a0a0a0;
  text-align: left;
  line-height: 1.4em;
  font-size: 1.3rem;

  @media ${md} {
    font-size: 1.5rem;

    p {
      margin-top: 1rem;
    }
  }

  p {
    margin-top: 1rem;
    a {
      text-align: center;
      color: ${props => props.theme.colors.primary};
    }
  }
`

const ResourceLink = ({ category, url, text, _destroy }) => {
  const isVideo = allowedVideoDomain(url)
  if (_destroy) {
    return null
  }

  if (isVideo) {
    return <ExplanationVideo url={url} text={text || ""} />
  } else {
    return (
      <LinkContainer>
        <i className="fa fa-text-article" title="Article" />
        <Link>
          <p>
            <a href={url} target="_blank" rel="noreferrer">
              {text}
            </a>
          </p>
        </Link>
      </LinkContainer>
    )
  }
}

export default ResourceLink
