import React from "react"
import { Field } from "formik"
import { FormikTextField as TextField } from "mill/components/TextField"
import Box from "shared/components/Box"
import Label from "mill/components/Label"
import Button from "shared/components/Button"
import { ResourceLinkFields, RemovalLink } from "./styles"
import { allowedDomain } from "mill/hooks/useVideoEmbed"

const ResourceLinkInput = props => {
  const { form, remove, push } = props

  const handleRemoveClick = (e, index) => {
    e.preventDefault()
    const resourceLinks = form.values.resourceLinks[index]
    if (resourceLinks.id) {
      // If the link has an ID, then hide the form and set destroy to true
      form.setFieldValue(`resourceLinks.${index}._destroy`, "true")
    } else {
      // Else, remove the field
      remove(index)
    }
  }

  // I appreciate this is a dumb check - but it doesn't really do any validation.
  // The real check happens once the form is submitted.
  const icon = url => {
    if (allowedDomain(url)) {
      return "video"
    } else {
      return "text-article"
    }
  }

  const onAddResource = e => {
    e.preventDefault()
    const {
      values: { resourceLinks }
    } = form
    const newAnswerCount = resourceLinks.length + 1
    if (newAnswerCount <= 10) {
      push({
        url: "",
        text: ""
      })
    }
  }

  const hasErrors = form?.touched?.resourceLinks && form?.errors?.resourceLinks

  return (
    <Box paddingTop="medium" paddingBottom="medium">
      <Box paddingBottom="xsmall">
        <Label hasError={hasErrors} title="Resource Links" />
      </Box>

      {form.values.resourceLinks.map((link, index) => (
        <ResourceLinkFields
          hidden={link._destroy}
          className="resource-link-input"
          id={`qa--link-${index}`}
          key={index}>
          <Field
            name={`resourceLinks.${index}._destroy`}
            type="hidden"
            value={link._destroy || "false"}
          />

          <Field name={`resourceLinks.${index}.id`} type="hidden" />

          <RemovalLink href="#" onClick={e => handleRemoveClick(e, index)}>
            Remove resource
          </RemovalLink>

          <Field
            name={`resourceLinks.${index}.text`}
            fullWidth
            type="text"
            component={TextField}
            label="Link text"
            placeholder="A descriptive title for the link..."
            value={link.text}
          />

          <Field
            withFieldset
            name={`resourceLinks.${index}.url`}
            fullWidth
            type="text"
            label="Link URL"
            icon={icon(link.url)}
            component={TextField}
            placeholder="https://"
            value={link.url}
          />
        </ResourceLinkFields>
      ))}
      <Button
        color="primary"
        variant="hollow"
        data-qa="add-resource"
        onClick={onAddResource}
        label={"Add resource"}
      />
    </Box>
  )
}

export default ResourceLinkInput
