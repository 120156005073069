import React from "react"
import { useQuery } from "@apollo/client"
import { darken } from "polished"
import FETCH_COMMENTABLE_COUNT from "mill/graphql/FetchCommentableCount"
import styled from "styled-components"

const CommentIcon = styled.div`
  display: relative;

  svg path {
    fill: ${props => props.theme.colors.primary};
  }

  span {
    background: ${props => darken(0.1, props.theme.colors.primary)};
    position: absolute;
    color: white;
    border-radius: 2rem;
    height: 1.4rem;
    min-width: 1.4rem;
    padding: 0 0.3rem;
    font-size: 0.9rem;
    font-weight: bold;
    right: 0.5rem;
    bottom: 0.5rem;
    display: grid;
    align-items: center;
    justify-content: center;
  }
`

const CommentCount = ({ commentableGid }) => {
  const { data } = useQuery(FETCH_COMMENTABLE_COUNT, {
    variables: {
      commentableGid
    }
  })

  const count = data?.commentable?.commentCount || 0

  return (
    <CommentIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="1.5em"
        viewBox="0 0 512 512">
        <path d="M112.7 358.8l-10.8 28.9-7 18.5 57.6-17.3 13.9-4.2 13.9 4.2c23.3 7.1 48.8 11 75.7 11c124.7 0 208-80.5 208-160s-83.3-160-208-160S48 160.5 48 240c0 35.1 15 68.9 42.9 96.9l21.8 21.9zm-39 103.9L16 480l21.2-56.5 19.8-52.7C21.3 335.1 0 289.6 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208s-114.6 208-256 208c-31.5 0-61.7-4.6-89.6-13.1L73.8 462.7z" />
      </svg>
      {count > 0 && <span>{count}</span>}
    </CommentIcon>
  )
}

CommentCount.defaultProps = {
  count: 0
}

export default CommentCount
