import React from "react"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import { pluralize } from "shared/utils/pluralize"
import { Container, Error } from "./styles"

const AccountResultCard = ({ account }) => {
  const exceededSeatAllocation = account.learnerCount > account.paidSeats
  return (
    <Container
      to={`/accounts/${account.id}`}
      id={`account-${account.id}`}
      data-qa="account-link">
      <Box
        display={{ tablet: "flex" }}
        justifyContent={{ tablet: "space-between" }}>
        <Box>
          <strong>{account.name}</strong>
          <small>
            {account.learnerCount} {pluralize("learner", account.learnerCount)}{" "}
            out of {account.paidSeats || "0"} paid{" "}
            {pluralize("seat", account.paidSeats)} &middot; Contract renewal on{" "}
            {account.friendlyLicenseRenewalDate}
          </small>
          {account.friendlyDeletionAt && (
            <Error>Pending deletion on {account.friendlyDeletionAt}</Error>
          )}
        </Box>
        {exceededSeatAllocation && (
          <Box
            display={{ mobile: "none", tablet: "flex" }}
            alignItems="center"
            marginLeft="small"
            maxWidth="xsmall">
            <Box display={{ mobile: "none", tablet: "inline-block" }}>
              <Text color="error" size="xsmall">
                <i className="fa fa-exclamation-circle" />
              </Text>
            </Box>
            <Box paddingLeft="xsmall" display="inline-block">
              <Text size="xsmall">
                This account has exceeded their paid seat allocation
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </Container>
  )
}
export default AccountResultCard
