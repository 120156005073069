import React from "react"
import ComponentLink from "shared/components/Link"
import styled from "styled-components"
import { darken } from "polished"

export const NotificationList = styled.div`
  > div {
    border-top: 1px solid #f8f8f8;
  }
  > div:first-child {
    border-top: none;
  }
`

export const ReadMarking = styled.i`
  display: inline-block;
  background: ${props => (props.read ? "#F3F4F3" : props.theme.colors.primary)};
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  margin-top: 0.5rem;
  cursor: pointer;

  &:hover {
    background: ${props =>
      props.read
        ? darken(0.05, "#F3F4F3")
        : darken(0.05, props.theme.colors.primary)};
  }
`

export const NotificationText = styled.div`
  p:last-child {
    margin-top: 0.3rem;
  }
`

export const Link = styled(ComponentLink)`
  display: block;
  background-color: white;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgb(251, 251, 251);
  }
`
