import React from "react"
import { Container } from "./styles"

const defaultImage = "https://yarno.imgix.net/static/default.jpg"

const QuestionImage = ({ imageUrl, loaded, openVideoModal }) => {
  const imageSrc = imageUrl || defaultImage
  const isSvg = imageUrl && imageUrl.includes(".svg")

  if (loaded) {
    return (
      <Container
        data-qa="question-image"
        clickable={openVideoModal}
        onClick={openVideoModal}
        style={{
          backgroundImage: `url(${
            isSvg ? defaultImage : imageSrc
          }?w=1200&auto=format,compress&q=90)`
        }}>
        {openVideoModal && (
          <div>
            <i className={"fa fa-play-circle"} />
          </div>
        )}
      </Container>
    )
  }
  return <Container />
}

export default QuestionImage
