import React from "react"
import Link from "shared/components/Link"
import styled from "styled-components"
import colors from "shared/utils/colors"

export const StyledButton = styled.button`
  border-radius: 3px;
  border-style: solid;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-indent: 1px;
  font-family: "Raleway";
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  transition: all 0.1s ease-out;
  ${props => props.fullWidth && `display: block; width: 100%;`}
  font-size: 1.4rem;
  border-width: 2px;
  padding: 1.1rem 1rem;
  img {
    padding-left: 1rem;
    height: 1rem;
    position: relative;
    top: -1px;
  }
  ${props => `
    background: ${props.theme.buttons.primary.backgroundColor};
    border-color: ${props.theme.buttons.primary.borderColor};
    color: ${props.theme.buttons.primary.fontColor};
    &:hover {
      background: ${props.theme.buttons.primary.hover.backgroundColor};
      border-color: ${props.theme.buttons.primary.hover.borderColor};
      color: ${props.theme.buttons.primary.hover.fontColor}
    }
  `}

  ${props =>
    props.hollow &&
    `
    background: transparent;
    border-color: ${props.theme.buttons.primaryHollow.borderColor};
    color: ${props.theme.buttons.primaryHollow.fontColor};
    &:hover {
      background: transparent;
      border-color: ${props.theme.buttons.primaryHollow.hover.borderColor};
      color: ${props.theme.buttons.primaryHollow.hover.fontColor};
    }
    `}

  ${props =>
    props.secondary &&
    `background: ${props.theme.buttons.secondary.backgroundColor};
    border-color: ${props.theme.buttons.secondary.borderColor};
    color: ${props.theme.buttons.secondary.fontColor};
    &:hover {
      background: ${props.theme.buttons.secondary.hover.backgroundColor};
      border-color: ${props.theme.buttons.secondary.hover.borderColor};
      color: ${props.theme.buttons.secondary.hover.fontColor};
    }`}

  ${props =>
    props.secondary &&
    props.hollow &&
    `background: transparent;
    border-color: ${props.theme.buttons.secondaryHollow.borderColor};
    color: ${props.theme.buttons.secondaryHollow.fontColor};
    &:hover {
      background: transparent;
      border-color: ${props.theme.buttons.secondaryHollow.hover.borderColor};
      color: ${props.theme.buttons.secondaryHollow.hover.fontColor};
    }
  `}

  ${props =>
    props.error &&
    `
    background: ${props.theme.colors.error};
    border-color: ${props.theme.colors.error};
    color: #FFFFFF;
    &:hover {
      background: ${props.theme.colors.errorHover};
      border-color: ${props.theme.colors.errorHover};
      color: #FFFFFF;
    }
  `}

  ${props =>
    props.error &&
    props.hollow &&
    `
    background: transparent;
    border-color: ${props.theme.colors.error};
    color: ${props.theme.colors.error};
    &:hover {
      background: transparent;
      border-color: ${props.theme.colors.errorHover};
      color: ${props.theme.colors.errorHover};
    }
  `}

  ${props =>
    props.selected &&
    `
    background: ${props.theme.colors.primary};
    color: white;
    &:hover {
      border-color: ${props.theme.colors.primary};
      background: ${props.theme.colors.primary};
      color: white;
    }
  `};

  ${props =>
    props.tiny &&
    `
    font-size: 1.1rem;
    border-width: 2px;
    padding: 0.4rem 0.4rem;
    img {
      padding-left: 0.4rem;
      height: 0.7rem;
      position: relative;
      top: -1px;
    }
  `}

  ${props =>
    props.small &&
    `
    font-size: 1.2rem;
    border-width: 2px;
    padding: 0.6rem;
    img {
      padding-left: 0.6rem;
      height: 0.8rem;
      position: relative;
      top: -1px;
    }
  `}

  ${props =>
    props.large &&
    `
    font-size: 1.5rem;
    border-width: 2px;
    padding: 0 1.8rem;
    height: 6rem;
    min-width: 21rem;
    small {
      font-size: 1.2rem;
    }
    img {
      padding-left: 1.4rem;
      height: 1.3rem;
      position: relative;
      top: -2px;
    }
  `}

  &:hover {
    transition: all 0.1s ease-in;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background: ${colors.grayAthens};
    border-color: ${colors.grayAthens};
    color: white;
    &:hover {
      background: ${colors.grayAthens};
      border-color: ${colors.grayAthens};
      cursor: not-allowed;
      color: white;
    }
  }
`

export const StyledButtonLink = styled.span`
  display: ${props => (props.fullWidth ? `block` : `inline-block`)};
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border-style: solid;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-indent: 1px;
    font-family: Raleway;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    text-decoration: none;
    transition: all 0.1s ease-out;
    font-size: 1.4rem;
    border-width: 2px;
    padding: 1.1rem 1rem;
    img {
      padding-left: 1rem;
      height: 1rem;
      position: relative;
      top: -1px;
    }
    ${props => `
    background: ${props.theme.buttons.primary.backgroundColor};
    color: ${props.theme.buttons.primary.fontColor};
    border-color: ${props.theme.buttons.primary.borderColor};
    &:hover {
      background: ${props.theme.buttons.primary.hover.backgroundColor};
      border-color: ${props.theme.buttons.primary.hover.borderColor};
      color: ${props.theme.buttons.primary.hover.fontColor}
    }`}

    ${props =>
      props.primary &&
      props.hollow &&
      `
      background: transparent;
      border-color: ${props.theme.buttons.primaryHollow.borderColor};
      color: ${props.theme.buttons.primaryHollow.fontColor};
      &:hover {
        background: transparent;
        border-color: ${props.theme.buttons.primaryHollow.hover.borderColor};
        color: ${props.theme.buttons.primaryHollow.hover.fontColor};
      }
      `}

    ${props =>
      props.secondary &&
      `background: ${props.theme.buttons.secondary.backgroundColor};
      border-color: ${props.theme.buttons.secondary.borderColor};
      color: ${props.theme.buttons.secondary.fontColor};
      &:hover {
        background: ${props.theme.buttons.secondary.hover.backgroundColor};
        border-color: ${props.theme.buttons.secondary.hover.borderColor};
        color: ${props.theme.buttons.secondary.hover.fontColor};
      }`}

    ${props =>
      props.secondary &&
      props.hollow &&
      `background: transparent;
      border-color: ${props.theme.buttons.secondaryHollow.borderColor};
      color: ${props.theme.buttons.secondaryHollow.fontColor};
      &:hover {
        background: transparent;
        border-color: ${props.theme.buttons.secondaryHollow.hover.borderColor};
        color: ${props.theme.buttons.secondaryHollow.hover.fontColor};
      }
    `}

    ${props =>
      props.error &&
      `
      background: ${props.theme.colors.error};
      border-color: ${props.theme.colors.error};
      color: #FFFFFF;
      &:hover {
        background: ${props.theme.colors.errorHover};
        border-color: ${props.theme.colors.errorHover};
        color: #FFFFFF;
      }
    `}

    ${props =>
      props.error &&
      props.hollow &&
      `
      background: transparent;
      border-color: ${props.theme.colors.error};
      color: ${props.theme.colors.error};
      &:hover {
        background: transparent;
        border-color: ${props.theme.colors.errorHover};
        color: ${props.theme.colors.errorHover};
      }
    `}

    ${props =>
      props.tiny &&
      `
      font-size: 1.1rem;
      border-width: 2px;
      padding: 0.4rem 0.4rem;
      img {
        padding-left: 0.4rem;
        height: 0.7rem;
        position: relative;
        top: -1px;
      }
    `}

    ${props =>
      props.small &&
      `
      font-size: 1.2rem;
      border-width: 2px;
      padding: 0.6rem;
      img {
        padding-left: 0.6rem;
        height: 0.8rem;
        position: relative;
        top: -1px;
      }
    `}

    ${props =>
      props.large &&
      `
      font-size: 1.5rem;
      border-width: 2px;
      padding: 0 1.8rem;
      height: 6rem;
      min-width: 21rem;
      small {
        font-size: 1.2rem;
      }
      img {
        padding-left: 1.4rem;
        height: 1.3rem;
        position: relative;
        top: -2px;
      }
    `}

    &:hover {
      transition: all 0.1s ease-in;
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background: ${colors.grayAthens};
      border-color: ${colors.grayAthens};
      &:hover {
        background: ${colors.grayAthens};
        border-color: ${colors.grayAthens};
        cursor: not-allowed;
      }
    }
  }
`

const Button = props => {
  const innards = () => {
    return (
      <React.Fragment>
        {props.label}
        {props.arrow && (
          <i
            className={`fa fa-chevron-right-fas`}
            style={{ paddingLeft: "1rem" }}
          />
        )}
        {props.icon && <i className={`fa ${props.icon}`} />}
      </React.Fragment>
    )
  }

  if (props.href) {
    const to = props.disabled ? "#" : props.href
    return (
      <StyledButtonLink {...props}>
        <a href={to}>{innards()}</a>
      </StyledButtonLink>
    )
  }

  if (props.to) {
    const to = props.disabled ? "#" : props.to
    return (
      <StyledButtonLink {...props}>
        <Link to={to}>{innards()}</Link>
      </StyledButtonLink>
    )
  }

  return (
    <StyledButton {...props}>
      {props.label}
      {props.icon && <i className={`fa ${props.icon}`} />}
    </StyledButton>
  )
}

export default Button
