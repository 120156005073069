import React, { Fragment, useState } from "react"
import { useFormikContext } from "formik"
import Box from "shared/components/Box"
import Label from "mill/components/Label"
import Text from "shared/components/Text"
import ImageURL from "shared/components/ImageURL"
import Checkbox from "shared/components/Checkbox"
import VideoURL from "mill/components/VideoURL"
import { MediaSelection } from "./styles"
import isVideoMedia from "mill/utils/isVideoMedia"

const QuestionMediaInput = () => {
  const { values, setFieldValue, validateForm, touched, errors } =
    useFormikContext()
  const isImage = values.mediaType === "image"
  const isVideo = isVideoMedia(values.mediaType)
  const [storedImageUrl, setStoredImageUrl] = useState(
    (isImage && values.mediaUrl) || ""
  )
  const [storedVideoUrl, setStoredVideoUrl] = useState(
    (isVideo && values.mediaUrl) || ""
  )

  const hasMediaError =
    (touched?.mediaUrl && errors?.mediaUrl) ||
    (touched?.customVideoThumbnail && errors?.customVideoThumbnail)

  const toggleMediaType = type => {
    const mediaUrl = values.mediaUrl
    const mediaType = values.mediaType
    if (type !== mediaType) {
      // By setting false here, we skip the validation. This prevents
      // the submit button from disabling when toggling between the two types.
      // The side effect of this is that the answer tab can be navigated to.
      // We fix the side effect by manually calling validateForm.
      if (type === "image") {
        setStoredVideoUrl(mediaUrl)
        setFieldValue("mediaUrl", storedImageUrl, false).then(() => {
          setFieldValue("mediaType", "image", false).then(() => {
            validateForm()
          })
        })
      } else if (type === "video") {
        setStoredImageUrl(mediaUrl)
        setFieldValue("mediaUrl", storedVideoUrl, false).then(() => {
          setFieldValue("mediaType", "video", false).then(() => {
            validateForm()
          })
        })
      }
    }
  }

  const handleThumbnailCheck = checked => {
    // Clear customVideoThumbnailUrl if checked
    if (checked) {
      setFieldValue("customVideoThumbnailUrl", "").then(() => {
        validateForm()
      })
    }
    setFieldValue("customThumbnail", !checked).then(() => {
      validateForm()
    })
  }

  const handleVerticalVideoCheck = checked => {
    setFieldValue("verticalVideo", checked).then(() => {
      validateForm()
    })
  }

  return (
    <Box>
      <Text component="label" size="xlarge" weight="strong" tone="dark">
        <Label
          hasError={hasMediaError}
          title={
            <Fragment>
              Question media <small>optional</small>
            </Fragment>
          }>
          Your image/video will be automatically resized, for best display on
          all devices.
        </Label>
        <Text component="span" display="block"></Text>
      </Text>
      <Box>
        <Box display="grid" gridGap="1rem" gridTemplateColumns="1fr 1fr">
          <MediaSelection
            data-qa="add-image"
            active={values.mediaType === "image"}
            onClick={() => toggleMediaType("image")}>
            <i className="fa fa-image" />
            Add image
          </MediaSelection>

          <MediaSelection
            data-qa="add-video"
            active={isVideo}
            onClick={() => toggleMediaType("video")}>
            <i className="fa fa-video" />
            Add video
          </MediaSelection>
        </Box>
      </Box>

      {isImage && (
        <Box paddingTop="medium">
          <ImageURL fieldName={"mediaUrl"} changeFieldValue={setFieldValue} />
        </Box>
      )}
      {isVideo && (
        <Fragment>
          <Box paddingTop="medium">
            <VideoURL
              mediaUrl={values.mediaUrl}
              changeFieldValue={setFieldValue}
            />
            <Box paddingRight="small" paddingBottom="large">
              <Checkbox
                label={"This video is in vertical (portrait) orientation"}
                data-qa="vertical-video"
                onCheck={() => handleVerticalVideoCheck(true)}
                onUnCheck={() => handleVerticalVideoCheck(false)}
                checked={values.verticalVideo}
                alignWithContent
                marginRight
              />
            </Box>
            <Box paddingBottom="xsmall">
              <Text component="label" size="xlarge" weight="strong" tone="dark">
                Thumbnail
                <Text component="span" display="block" size="small">
                  A video thumbnail is a static image that represents your
                  video.
                </Text>
              </Text>
            </Box>

            <Box display="flex" flexWrap="wrap">
              <Box paddingRight="small">
                <Checkbox
                  label={"Use video thumbnail"}
                  data-qa="video-thumbnail"
                  onCheck={() => handleThumbnailCheck(true)}
                  onUnCheck={() => handleThumbnailCheck(false)}
                  checked={!values.customThumbnail}
                  alignWithContent
                  marginRight
                />
              </Box>
              <Checkbox
                label={"Use custom thumbnail"}
                data-qa="custom-thumbnail"
                onCheck={() => handleThumbnailCheck(false)}
                onUnCheck={() => handleThumbnailCheck(true)}
                checked={values.customThumbnail}
                alignWithContent
                marginRight
              />
            </Box>
          </Box>

          {values.customThumbnail && (
            <Box paddingTop="medium">
              <ImageURL
                fieldName={"customVideoThumbnailUrl"}
                label={"Custom Thumbnail URL"}
                changeFieldValue={setFieldValue}
              />
            </Box>
          )}
        </Fragment>
      )}
    </Box>
  )
}

export default QuestionMediaInput
