import React, { Fragment, useState } from "react"
import Link from "shared/components/Link"
import useNotifications from "shared/hooks/useApplicationNotifications"
import NotificationGroup from "shared/components/NotificationGroup"
import Imgix from "mill/components/Imgix"
import Box from "shared/components/Box"
import Text from "shared/components/Text"

import {
  RelativePositioningContainer,
  AbsolutelyPositionedContainer,
  Bell,
  Container,
  Header,
  NotificationContainer,
  UnreadCount,
  AllRead,
  ViewAll
} from "./styles"

const Notifications = ({ allNotificationsPath }) => {
  const {
    notifications,
    notificationCount,
    unreadNotificationCount,
    hasNotifications,
    hasUnreadNotifications,
    groupedNotifications,
    previouslyHadNotifications,
    handleReadNotifications,
    handleReadToggle,
    toggleVisibility,
    visible,
    visiblePreviously,
    setVisible,
    ref
  } = useNotifications({ notificationVariables: { first: 5 } })

  return (
    <Fragment>
      <RelativePositioningContainer>
        <Bell
          id="notification-bell"
          data-visible={visible ? "true" : "false"}
          visible={visible}
          hasUnreadNotifications={hasUnreadNotifications}
          onClick={toggleVisibility}
          href="#">
          <i className={`fa fa-bell${hasUnreadNotifications ? `-on` : ``}`} />
        </Bell>
        <AbsolutelyPositionedContainer visible={visible}>
          <Container
            data-qa="notification-frame"
            visiblePreviously={visiblePreviously}
            visible={visible}
            ref={ref}>
            <div>
              <Header>
                <Box
                  paddingLeft="medium"
                  paddingRight="medium"
                  paddingTop="medium"
                  paddingBottom="medium"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end">
                  <Box display="flex">
                    <Text
                      size="large"
                      family="heading"
                      tone="dark"
                      weight="strong">
                      Notifications
                    </Text>
                    {hasNotifications && (
                      <UnreadCount data-qa="unread-count">
                        {unreadNotificationCount}
                      </UnreadCount>
                    )}
                  </Box>
                  <Text weight="mstrong" size="msmall">
                    {hasUnreadNotifications ? (
                      <a href="#" onClick={handleReadNotifications}>
                        Mark all as read
                      </a>
                    ) : (
                      previouslyHadNotifications && <AllRead>All read</AllRead>
                    )}
                  </Text>
                </Box>
              </Header>

              {hasNotifications ? (
                <NotificationContainer>
                  <NotificationGroup
                    title="Today"
                    notifications={groupedNotifications.today}
                    handleReadToggle={handleReadToggle}
                  />
                  <NotificationGroup
                    title="Yesterday"
                    notifications={groupedNotifications.yesterday}
                    handleReadToggle={handleReadToggle}
                  />
                  <NotificationGroup
                    title="This week"
                    notifications={groupedNotifications.thisWeek}
                    handleReadToggle={handleReadToggle}
                  />
                  <NotificationGroup
                    title="Last week"
                    notifications={groupedNotifications.lastWeek}
                    handleReadToggle={handleReadToggle}
                  />
                  <NotificationGroup
                    title="This month"
                    notifications={groupedNotifications.thisMonth}
                    handleReadToggle={handleReadToggle}
                  />
                  <NotificationGroup
                    title="This month"
                    notifications={groupedNotifications.lastMonth}
                    handleReadToggle={handleReadToggle}
                  />
                  <NotificationGroup
                    title="Older"
                    notifications={groupedNotifications.older}
                    handleReadToggle={handleReadToggle}
                  />
                </NotificationContainer>
              ) : (
                <Box style={{ borderTop: "1px solid #f3f4f3" }}>
                  <Box
                    paddingLeft="medium"
                    paddingTop="large"
                    paddingBottom="large">
                    <Imgix
                      srcPath="/static/invitation-letter-hand.png"
                      alt="No notifications"
                    />
                  </Box>

                  <Box
                    paddingLeft="medium"
                    paddingRight="medium"
                    paddingBottom="large">
                    <Text
                      align="center"
                      size="large"
                      family="heading"
                      tone="dark"
                      weight="strong">
                      No notifications
                    </Text>
                    <Box paddingTop="xsmall">
                      <Text align="center" size="small">
                        Good things come to those who wait! Important info
                        heading your way soon.
                      </Text>
                    </Box>
                  </Box>
                </Box>
              )}

              {previouslyHadNotifications && (
                <ViewAll
                  paddingLeft="medium"
                  paddingRight="medium"
                  paddingTop="small"
                  paddingBottom="small">
                  <Text weight="mstrong" size="msmall">
                    <Link to={allNotificationsPath}>
                      View all notifications
                    </Link>
                  </Text>
                </ViewAll>
              )}
            </div>
          </Container>
        </AbsolutelyPositionedContainer>
      </RelativePositioningContainer>
    </Fragment>
  )
}

Notifications.defaultProps = {
  allNotificationsPath: "/admin/notifications"
}

export default Notifications