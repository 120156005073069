import { useQuery, useMutation, gql } from "@apollo/client"
import { useParams } from "react-router-dom"

import CARTWRIGHT_FETCH_TOPICS from "cartwright/graphql/FetchTopics"
import FETCH_TOPICS from "mill/graphql/FetchTopics"
import CREATE_TOPIC from "mill/graphql/CreateTopic"

const useTopicOptions = () => {
  const { accountId } = useParams()
  const accountVariables = accountId ? { accountId } : {}
  const TOPIC_QUERY = accountId ? CARTWRIGHT_FETCH_TOPICS : FETCH_TOPICS

  const { data } = useQuery(TOPIC_QUERY, {
    variables: accountVariables
    // nextFetchPolicy: "cache-only"
  })

  const [createTopic] = useMutation(CREATE_TOPIC, {
    onCompleted: ({ createTopic }) => {
      if (createTopic.errors.length) {
        displayNotification({
          text: createTopic.errors[0],
          style: "error"
        })
      }
    },
    update(
      cache,
      {
        data: {
          createTopic: { topic }
        }
      }
    ) {
      cache.modify({
        fields: {
          topics(existingTopics = []) {
            const newTopicRef = cache.writeFragment({
              data: topic,
              fragment: gql`
                fragment NewTopic on Topic {
                  id
                  name
                }
              `
            })
            return [...existingTopics, newTopicRef]
          }
        }
      })
    }
  })

  const topicOptions = data
    ? data.topics.map(topic => {
        return { label: topic.name, value: topic.id }
      })
    : []

  return {
    topicOptions,
    createTopic
  }
}

export default useTopicOptions
