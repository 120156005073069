import styled from "styled-components"
import NavLink from "shared/components/NavLink"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
`

export const SubnavBar = styled.nav`
  background: #efefef;
  margin: 0 -3rem;
  padding: 1rem 3rem;
`

export const NavItem = styled(NavLink)`
  margin-right: 2rem;
  color: ${(props) => {
    return props.theme.colors.admin
  }};
  text-decoration: none;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.3rem;
  opacity: 0.4;

  &:hover {
    opacity: 0.6;
  }

  &.active {
    opacity: 0.8;
  }
`

export const FormActions = styled.div`
  background: #f9fafb;
  padding: 1.5rem;
  margin: 1.5rem -1.5rem -1.5rem -1.5rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
`

export const FormSection = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  background: #ffffff;
  border: 1px solid #f8f8f8;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  padding: 2rem;
  margin: 1rem 0;

  th {
    text-align: left;
  }
`

export const Fieldset = styled.fieldset`
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
  border-style: none;
  border-top: 1px solid #d7dadb;
  padding: 0 0 2rem 0;
  position: relative;

  > div:first-of-type {
    width: 30%;
  }

  legend {
    display: block;
    font-size: 1.8rem;
    color: #25c1a9;
    font-weight: bold;
    font-family: "Raleway", sans-serif;
    margin-top: 2rem;
  }

  p {
    font-size: smaller;
  }
`

export const FieldGroup = styled.div`
  width: 60%;
  label {
    display: block;
    margin-top: 2rem;
  }
  input, textarea {
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
  }
  input {
    padding: 1rem;
    width: 100%;

    &[type="checkbox"] {
      margin: 1rem 1rem 1rem 0;
      width: auto;
    }

    &[type="text"],
    &[type="number"] {
      width: 100%;
    }
  }
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }

  textarea {
    font-family: "Open Sans", sans-serif;
    display: block;
    width: 100%;
  }
`

export const SplitFieldGroup = styled.div`
  > div {
    display: inline-block;
    width: 46%;
    &:first-of-type {
      margin-right: 8%;
    }
  }
`

export const SearchBox = styled.input`
  margin-bottom: 2rem;
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 1rem;
  font-size: 1.7rem;
`

export const Well = styled.div`
  border: 3px solid ${(props) => props.theme.colors.border};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
`

export const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;
`

export const SearchWithFilter = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr;
  column-gap: 3rem;
`

export const HelpText = styled.small`
  margin-top: 0.4rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.3em;
  opacity: 0.9;
`

export const FieldError = styled.span`
  color: ${(props) => props.theme.colors.error};
`