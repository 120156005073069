import styled from "styled-components"
import colors from "shared/utils/colors"

export const StatusCircle = styled.span`
width: 20px;
height: 20px;
margin-right: 2rem;
display: inline-block;
vertical-align: middle;
border-radius: 50%;
background: ${colors.grayAthens};
border: 5px solid #F1F2F2;
  ${props =>
    props.warmup === "true" &&
    `background: ${props.theme.colors.secondary};
    border: 5px solid #FAC655;`}
  ${props =>
    props.active === "true" &&
    `background: ${props.theme.colors.primary};
  border: 5px solid #92E0D4;`}
  ${props =>
    props.cooldown === "true" &&
    `background: #7DB5FF;
  border: 5px solid #B0D2FF;`};
`
