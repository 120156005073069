import styled from "styled-components"
import Link from "shared/components/Link"

export const Container = styled(Link)`
  text-decoration: none;
  display: block;
  background: white;
  padding: 1.5rem 2rem;
  color: #535353;
  border-top: 2px solid #eee;

  &:hover {
    background: #efefef;
    cursor: pointer;
  }

  strong,
  em {
    display: block;
  }
`

export const Error = styled.small`
  display: block;
  font-weight: bold;
  color: ${props => props.theme.colors.error};
`
