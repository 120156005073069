import React from "react"
import styled from "styled-components"
import { sm, md, lg } from "mill/utils/breakpoints"

const Container = styled.div`
  max-width: 140rem;
  margin: 0 auto;
  padding: 0 3rem;

  @media ${md} {
    padding: 0 4rem;
  }
`

const Wrapper = ({ children }) => {
  return <Container>{children}</Container>
}

export default Wrapper
