import React, { useRef, Fragment } from "react"
import { rgba } from "polished"
import moment from "moment"
import styled from "styled-components"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import floatToPercentage from "cartwright/utils/floatToPercentage"
import { useQuery } from "@apollo/client"
import EngagementChart from "cartwright/components/EngagementChart"
import { Container, Sidebar, Content } from "cartwright/components/Layout"
import PlatformNavigation from "cartwright/components/Platform/PlatformNavigation"

import FETCH_PLATFORM_ENGAGEMENT from "cartwright/graphql/FetchPlatformEngagement"

const Table = styled.table`
  th,
  td {
    text-align: right;
    padding: 0.5rem 1rem;
  }

  tr[data-good="true"] td {
    background: ${props => rgba(props.theme.colors.primary, 0.4)};
  }

  tr[data-bad="true"] td {
    background: ${props => rgba(props.theme.colors.error, 0.4)};
  }

  tr[data-total] {
    td {
      border-top: 2px solid #ddd;
      font-weight: bold;
    }
  }
`

const Screen = () => {
  const HIGH_ENGAGEMENT_PERCENTAGE = 90.0
  const LOW_ENGAGEMENT_PERCENTAGE = 30.0
  const { data } = useQuery(FETCH_PLATFORM_ENGAGEMENT)
  const platform = data?.platform
  const accounts = data?.accounts?.nodes
  const hasRollingChart = platform
  const hasAccounts = accounts && accounts.length > 0

  return (
    <Box>
      <h1>Engagement</h1>
      <Container>
        <Sidebar>
          <PlatformNavigation />
        </Sidebar>
        <Content>
          <Box marginBottom="xlarge">
            <Heading level={2}>Rolling 3-month engaged learner count</Heading>
            <Text>
              The chart below shows the number of active learners (those that
              have tracked a page view in the application) for the given month
              and the 2 preceding months on a rolling basis for accounts within
              contract. For example, the March number below shows the number of
              unique engaged learners in the 90 days to 31 March (i.e. covering
              Jan/Feb/March). We calculate it this way because we believe a
              learner doing at least 1 campaign per 3 months is a good measure
              of Yarno being used effectively.
            </Text>
          </Box>
          {hasRollingChart && (
            <EngagementChart
              data={platform.learnersActiveRolling90DayFullMonths}
            />
          )}

          {hasAccounts && (
            <Fragment>
              <Box marginTop="large" marginBottom="medium">
                <Heading level={2}>
                  Engagement for{" "}
                  {moment(platform.engagementCutoffDate).format("MMMM")}
                </Heading>
                <Text>
                  The table below shows current engagement statistics for
                  accounts marked within contract. The Active learners data is
                  up to the last full month (i.e. in mid-September this would
                  show the unique engaged learners in the 90 days to 31 August).
                  Rows highlighted in red indicate an engagement rate of less
                  than 30%; rows highlighted in green indicate an engagement
                  rate of 70% or more.
                </Text>
              </Box>
              <Table>
                <thead>
                  <tr>
                    <th>Account name</th>
                    <th>Paid seats</th>
                    <th>Target learners</th>
                    <th>Active learners</th>
                    <th>Engagement</th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.map(account => {
                    const percentage = floatToPercentage(
                      account.accountEngagement,
                      2
                    )
                    return (
                      <tr
                        key={account.id}
                        data-good={percentage > HIGH_ENGAGEMENT_PERCENTAGE}
                        data-bad={
                          percentage < LOW_ENGAGEMENT_PERCENTAGE &&
                          account.targetLearners > 0
                        }>
                        <td>{account.name}</td>
                        <td>{account.paidSeats.toLocaleString("en-US")}</td>
                        <td>
                          {account.targetLearners.toLocaleString("en-US")}
                        </td>
                        <td>
                          {account.learnersActiveInLast3FullMonths.toLocaleString(
                            "en-US"
                          )}
                        </td>
                        <td>{percentage}%</td>
                      </tr>
                    )
                  })}
                  <tr data-total>
                    <td></td>
                    <td>{platform.totalPaidSeats.toLocaleString("en-US")}</td>
                    <td>
                      {platform.totalTargetLearners.toLocaleString("en-US")}
                    </td>
                    <td>
                      {platform.learnersActiveInLast3FullMonths.toLocaleString(
                        "en-US"
                      )}
                    </td>
                    <td>
                      {floatToPercentage(platform.platformEngagement, 2)}%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Fragment>
          )}
        </Content>
      </Container>
    </Box>
  )
}

export default Screen
