import React from "react"
import { darken } from "polished"
import styled from "styled-components"
import Box from "shared/components/Box"
import { sm, md, lg, xl } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

export const Container = styled.div`
  background: white;
  padding: 3rem 0 0 0;
`

export const Banner = styled.div`
  background-color: ${darken(0.03, "#2A4B55")};
  z-index: ${zIndex.base + 1};
  top: 5rem;

  @media ${lg} {
    top: 7rem;
    position: sticky;
  }

  h2 {
    color: ${props => props.theme.colors.primary};

    a {
      color: white;
    }

    span {
      margin: 0 1rem;
    }

    &.modified-heading {
      font-size: 2rem;

      button {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  p {
    color: rgba(255, 255, 255, 0.8);
  }
`

export const FormGrid = styled.div`
  div.ProseMirror {
    max-width: 55rem;
  }

  @media ${lg} {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: 49fr 45fr;
  }

  ${props =>
    props.open &&
    `
  @media ${xl} {
    padding-right: 36rem;
  }

  @media screen and (min-width: 108em) {
    padding-right: 0;
  }

  `}
`

export const FormContainer = styled(Box)`
  @media ${lg} {
    position: relative;
  }
`

export const Frame = styled.div`
  position: relative;
`

export const PreviewContainer = styled(Box)`
  display: none;

  @media ${md} {
    display: block;
    margin: 0 auto 0 auto;
    > div {
      position: sticky;
      top: 19rem;
    }
  }
  @media ${lg} {
    > div {
      top: 19rem;
    }
  }
`

export const Grid = ({ children }) => {
  return (
    <Box
      display={{ desktop: "grid" }}
      gridGap={{ desktop: "3rem" }}
      gridTemplateColumns={{ desktop: "1fr 1fr" }}>
      {children}
    </Box>
  )
}

export const PreviewIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.2rem;
  &:hover {
    cursor: pointer;
  }
`
