import React, { useState } from "react"
import { useLazyQuery } from "@apollo/client"
import styled from "styled-components"
import CampaignResultCard from "cartwright/components/CampaignResultCard"
import LearnerResultCard from "cartwright/components/LearnerResultCard"

import SEARCH_USERS from "cartwright/graphql/SearchUsers"
import SEARCH_CAMPAIGNS from "cartwright/graphql/SearchCampaigns"

const ResultsList = styled.div`
  padding-top: 2rem;
  text-align: left;
  margin: 2rem auto;
  max-width: 100rem;
`
const ResultsHeading = styled.div`
  text-align: left;
  margin: 2rem auto;
  max-width: 100rem;
`

const GiantSearchForm = styled.form`
  margin: 7rem auto;
  display: flex;
  max-width: 80rem;

  input,
  select {
    border: 2px solid #eee;
    padding: 2rem;
    font-size: 1.8rem;
    color: #535353;
    margin: 0 1rem;
    border-radius: 0.6rem;
  }

  button {
    border: none;
    margin-left: 1rem;
    border-radius: 0.6rem;
    font-size: 1.8rem;
    background: ${props => props.theme.colors.primary};
    color: white;
  }

  input {
    flex: 2;
    &:focus {
      outline: none;
    }
  }
  select {
    flex: 1;
  }
  button {
    flex: 1;
  }
`

const LearnerSearch = () => {
  const [inputValue, setInputValue] = useState("")
  const [searchValue, setSearchValue] = useState("")
  const [
    searchCampaigns,
    { loading: campaignsLoading, data: campaignResults }
  ] = useLazyQuery(SEARCH_CAMPAIGNS, {
    variables: { searchTerm: searchValue }
  })
  const [searchUsers, { loading: learnersLoading, data: learnerResults }] =
    useLazyQuery(SEARCH_USERS, {
      variables: {
        searchTerm: searchValue,
        includeActive: true,
        includeRetired: true
      }
    })

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const handleClick = e => {
    e.preventDefault()
    setSearchValue(inputValue)
    searchUsers(searchValue)
    searchCampaigns(searchValue)
  }

  const renderLearners = () => {
    if (searchValue === "") {
      return ""
    }
    if (!learnersLoading && learnerResults) {
      const learnerResultsArray = learnerResults.searchUsers

      return (
        <>
          {learnerResultsArray.length > 0 ? (
            <ResultsHeading>
              <h3>Learners</h3>
            </ResultsHeading>
          ) : (
            <ResultsHeading>
              <h3>No learners found</h3>
            </ResultsHeading>
          )}
          <ResultsList>
            {learnerResultsArray.map(learner => {
              return (
                <LearnerResultCard
                  showAccount
                  key={learner.id}
                  learner={learner}
                  to={`/accounts/${learner.accountId}/learners/${learner.id}`}
                />
              )
            })}
          </ResultsList>
        </>
      )
    }
  }

  const renderCampaigns = () => {
    if (searchValue === "") {
      return ""
    }
    if (!campaignsLoading && campaignResults) {
      const campaignResultsArray = campaignResults.campaigns.edges
      return (
        <>
          {campaignResultsArray.length > 0 ? (
            <ResultsHeading>
              <h3>Campaigns</h3>
            </ResultsHeading>
          ) : (
            <ResultsHeading>
              <h3>No campaigns found</h3>
            </ResultsHeading>
          )}
          <ResultsList>
            {campaignResultsArray.map(campaign => {
              return (
                <CampaignResultCard
                  key={campaign.node.id}
                  campaign={campaign.node}
                />
              )
            })}
          </ResultsList>
        </>
      )
    }
  }

  return (
    <>
      <GiantSearchForm>
        <input
          type="text"
          autoFocus={true}
          name="searchTerm"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Search by learner or campaign"
        />
        <button type="submit" onClick={handleClick}>
          Search
        </button>
      </GiantSearchForm>
      {renderLearners()}
      {renderCampaigns()}
    </>
  )
}

export default LearnerSearch
