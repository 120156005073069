import React from "react"
import styled from "styled-components"
import Modal from "shared/components/Modal"

const ModalInner = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    padding: 1rem;
    width: 50%;
    h2 {
      margin-top: 0;
    }
    img {
      margin: 0;
    }

    &:nth-of-type(2) {
      background-color: ${props => props.primaryThemeColor};

      h2 {
        color: white;
      }
    }
  }
`

const LogoPreviewModal = ({
  open,
  closeModal,
  primaryThemeColor,
  children
}) => {
  return (
    <Modal open={open} closeModal={closeModal} maxWidth="50rem" showCloseIcon>
      <ModalInner primaryThemeColor={primaryThemeColor}>{children}</ModalInner>
    </Modal>
  )
}

export default LogoPreviewModal
