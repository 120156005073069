import React, { Fragment } from "react"
import Link from "shared/components/Link"
import styled from "styled-components"

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: #535353;
  padding: 1.5rem 2rem;
  border-top: 2px solid #eee;

  strong,
  small {
    display: block;
    margin: 0;
  }

  &:hover {
    background: #efefef;
    cursor: pointer;
  }
`

const NewPlatformReport = () => {
  return (
    <Fragment>
      <h1>New Yarno-wide report</h1>
      <div>
        <StyledLink to={`/platform/reports/platform-overview-report/new`}>
          <strong>Platform overview report</strong>
          <small>Provides an overview of the entire Yarno platform.</small>
        </StyledLink>
      </div>
    </Fragment>
  )
}

export default NewPlatformReport
