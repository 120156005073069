import styled from "styled-components"

export const ResourceLinkFields = styled.div`
  ${props => props.hidden && `display: hidden;`}
  border-bottom: 2px solid #efefef;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
`

export const RemovalLink = styled.a`
  color: ${props => props.theme.colors.error};
  float: right;
  font-size: 1.4rem;

  &:hover {
    cursor: pointer;
  }
`
