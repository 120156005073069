import React, { Fragment } from "react"
import styled from "styled-components"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import Link from "shared/components/Link"
import HorizontalRule from "shared/components/HorizontalRule"
import withAccount from "cartwright/components/WithAccount"

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  color: #7a8381;
  padding: 1.5rem 2rem;
  border-top: 1px dashed #eaeaea;

  strong,
  small {
    display: block;
    margin: 0;
  }

  &:hover {
    background: #efefef;
    cursor: pointer;
  }
`

const NewAccountReport = ({ accountId }) => {
  return (
    <Fragment>
      <h1>New Report</h1>
      <Box marginBottom="small">
        <h2>Campaign reports</h2>
        <StyledLink to={`/accounts/${accountId}/reports/post-campaign/new`}>
          <Text weight="mstrong" color="mediumDark">
            Post-campaign report
          </Text>
          <small>Details on a full campaign</small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/campaign-signup/new`}>
          <Text weight="mstrong" color="mediumDark">
            Campaign signup report
          </Text>
          <small>
            Lists campaign learners and whether they've signed up to Yarno yet.
          </small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/learner-drilldown/new`}>
          <Text weight="mstrong" color="mediumDark">
            Campaign learner drilldown report
          </Text>
          <small>
            This is the same learner drilldown report that can be generated from
            a campaign dashboard in the main app.
          </small>
        </StyledLink>

        <StyledLink
          to={`/accounts/${accountId}/reports/campaign-leaderboard/new`}>
          <Text weight="mstrong" color="mediumDark">
            Campaign leaderboard report
          </Text>
          <small>
            Provides a handful of different leaderboards including the overall
            leaderboard, team grouping leaderboard, team leaderboard, top 10
            learners by group and top 10 learners by team.
          </small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/campaign-topic/new`}>
          <Text weight="mstrong" color="mediumDark">
            Campaign topic report
          </Text>
          <small>
            Breaks down topic performance on a campaign by learner, team and
            group.
          </small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/multi-campaign/new`}>
          <Text weight="mstrong" color="mediumDark">
            Multi-campaign report
          </Text>
          <small>
            Provides insight into the performance over multiple campaigns.
          </small>
        </StyledLink>

        {/* this particular report is specific to Australia Post */}
        {accountId === "70f54354-a9b8-401c-9385-56145d106257" && (
          <StyledLink
            to={`/accounts/${accountId}/reports/combined-campaign-report/new`}>
            <Text weight="mstrong" color="mediumDark">
              Combined campaign report
            </Text>
            <small>
              An Australia Post specific report that combines campaign
              statistics for two concurrently run campaigns.
            </small>
          </StyledLink>
        )}
      </Box>

      <HorizontalRule lineStyle="solid" lineWidth="2px" />

      <Box marginBottom="small">
        <h2>Account reports</h2>
        <StyledLink to={`/accounts/${accountId}/reports/account-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Account report
          </Text>
          <small>
            Provides details in to an account over a period of time.
          </small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/feedback-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Feedback report
          </Text>
          <small>
            Export all question, campaign and platform feedback for account.
          </small>
        </StyledLink>

        <StyledLink
          to={`/accounts/${accountId}/reports/learning-library-access-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Learning library access report
          </Text>
          <small>Lists when learners accessed learning library articles.</small>
        </StyledLink>

        <StyledLink
          to={`/accounts/${accountId}/reports/device-usage-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Device usage report
          </Text>
          <small>
            Details which devices and browsers are being used in Yarno.
          </small>
        </StyledLink>
      </Box>

      <HorizontalRule lineStyle="solid" lineWidth="2px" />

      <Box marginBottom="small">
        <h2>Learner reports</h2>
        <StyledLink to={`/accounts/${accountId}/reports/learner-export/new`}>
          <Text weight="mstrong" color="mediumDark">
            Learner export
          </Text>
          <small>Exports a list of all learners on the account.</small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/learner-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Learner report
          </Text>
          <small>Exports details for a single learner</small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/team-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Team report
          </Text>
          <small>
            Provides insight into the performance of a team in Yarno over time.
          </small>
        </StyledLink>

        <StyledLink to={`/accounts/${accountId}/reports/team-group-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Team group report
          </Text>
          <small>
            Provides insight into the performance of a team group in Yarno over
            time.
          </small>
        </StyledLink>
      </Box>

      <HorizontalRule lineStyle="solid" lineWidth="2px" />

      <Box marginBottom="small">
        <h2>Content reports</h2>
        <StyledLink to={`/accounts/${accountId}/reports/content-report/new`}>
          <Text weight="mstrong" color="mediumDark">
            Content report
          </Text>
          <small>
            Provides details into the performance of questions in Yarno over
            time.
          </small>
        </StyledLink>
      </Box>
    </Fragment>
  )
}

export default withAccount(NewAccountReport)
