import React from "react"
import { useMutation } from "@apollo/client"
import { Formik, Field } from "formik"
import styled from "styled-components"
import Box from "shared/components/Box"
import Button from "shared/components/Button"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Modal from "shared/components/Modal"

import useNotifications from "shared/hooks/useNotifications"

import UPDATE_CAMPAIGN_METADATA from "cartwright/graphql/UpdateCampaignMetadata"

const FieldGroup = styled.div`
  ${props => props.hidden && `display: none;`}

  h2 {
    display: inline-block;
    width: 20rem;
    font-size: 1.4rem;
  }

  input {
    display: inline-block;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 1rem;
  }
`

const UpdateLeaderboardScoreModal = ({
  modalOpen,
  toggleModal,
  campaign,
  leaderboardId,
  columns,
  refetchQuery
}) => {
  const { displayNotification } = useNotifications()

  const [updateCampaignMetadata] = useMutation(UPDATE_CAMPAIGN_METADATA, {
    onCompleted: data => {
      const campaignName = data.updateCampaignMetadata.campaign.name
      const message = `Custom scores have been updated for ${campaignName}`
      displayNotification({
        text: message,
        style: "success"
      })
      toggleModal(campaign)
      refetchQuery({
        id: leaderboardId
      })
    }
  })

  const hasCiToolbox = columns.find(col => col.key === "ciToolboxScore")
  const hasSpaghettiTower = columns.find(
    col => col.key === "spaghettiTowerScore"
  )

  return (
    <Modal
      open={modalOpen}
      closeModal={toggleModal}
      maxWidth="50rem"
      showCloseIcon>
      <Box textAlign="center" paddingBottom="medium">
        <Text tone="light">Setting scores for:</Text>
        <Heading level={2}>{campaign.name}</Heading>
      </Box>
      <div>
        <Formik
          initialValues={{
            spaghettiTowerScore: campaign.spaghettiTowerScore || "0",
            ciToolboxScore: campaign.ciToolboxScore || "0"
          }}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true)
            updateCampaignMetadata({
              variables: {
                campaignId: campaign.id,
                campaignMetadataAttributes: {
                  spaghettiTowerScore: values.spaghettiTowerScore,
                  ciToolboxScore: values.ciToolboxScore
                }
              }
            })
            actions.setSubmitting(false)
          }}
          render={({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <FieldGroup hidden={!hasSpaghettiTower}>
                <h2>Spaghetti Tower Score</h2>
                <Field name="spaghettiTowerScore" type="text" />
              </FieldGroup>
              <FieldGroup hidden={!hasCiToolbox}>
                <h2>Toolbox Score</h2>
                <Field name="ciToolboxScore" type="text" />
              </FieldGroup>

              <Box marginTop="small">
                <Button
                  type="submit"
                  label="Update score"
                  color="secondary"
                  disabled={isSubmitting}
                  fullWidth
                />
              </Box>
              <Box marginTop="small">
                <Button
                  label="Cancel"
                  type="button"
                  color="error"
                  variant="hollow"
                  onClick={toggleModal}
                  fullWidth
                />
              </Box>
            </form>
          )}
        />
      </div>
    </Modal>
  )
}

export default UpdateLeaderboardScoreModal
