import styled from "styled-components"
import {
  primaryHeadingColor,
  labelColor,
  orange_dark,
  grayBackground,
  grayBackgroundHover,
  paragraphColor,
  errorBackground
} from "./colors"
import { sm, md, lg } from "./breakpoints"
import zIndex from "shared/utils/zIndexLevels"
import { headingFont, bodyFont } from "./settings"
import { baseStyles } from "shared/components/Button/styles"

import Arrow from "../components/Button/arrow@2x.png"
import tooltip from "mill/components/Tooltip/info.png"
import tooltipHover from "mill/components/Tooltip/info-hover.png"

export const FormError = styled.div`
  border-left: 3px solid ${errorBackground};
  padding-left: 1rem;
  font-size: 0.8em;
  margin-top: 1.5rem;
`

export const PrimaryHeader = styled.h1`
  font-family: ${headingFont};
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2.6rem;
  color: ${primaryHeadingColor};
  letter-spacing: 2px;
  line-height: 4rem;
  margin: 0;
  padding: 0;
`

export const SecondaryHeader = styled.h2`
  font-family: ${bodyFont};
  font-size: 2rem;
  color: ${props => (props.darker ? labelColor : paragraphColor)};
  letter-spacing: 0;
  font-weight: normal;
  ${props => props.noMargin && `margin: 0;`};
`

export const Button = styled.button`
  margin-bottom: 4rem;
  min-width: 28rem;
  text-decoration: none;
  font-size: 1.6rem;
  position: relative;
  transition: background 0.2s ease-out;
  display: inline-block;
  background: ${props => props.theme.colors.secondary};
  color: white;
  cursor: pointer;
  margin-top: 1.5rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;

  padding: ${props => (props.primary ? "2rem 4rem" : "1rem 2rem")};
  border-radius: ${props => (props.primary ? "10px" : "5px")};

  &:hover {
    background-color: ${orange_dark};
  }

  &:before {
    content: "";
    background-image: ${props => (props.primary ? `url(${Arrow})` : "")};
    background-size: 100%;
    width: 6px;
    height: 10px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`

export const FlexBetweenContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;

  & > div {
    flex: 0 0 100%;
  }

  @media ${sm} {
    & > div {
      flex: 0 0 50%;
    }
  }

  @media ${lg} {
    & > div {
      flex: 0 0 25%;
    }
  }
`

export const Wrapper = styled.div`
  max-width: 140rem;
  margin: 0 auto;
  ${props => props.relative && "position: relative"};
  padding: 0 1.5rem;
`

export const DownloadLink = styled.a`
  ${baseStyles}
  background-color: #fafdff;
  border-color: #fafdff;
  display: inline-block;
  font-size: 1.4rem;
  color: #8f9091;
  padding: 1.1rem 1rem;
  margin-bottom: 1.5rem;
  width: 100%;

  ${props =>
    props.generating == "true" &&
    `
    background-color: transparent;
    &:hover {
      cursor: auto;
    }
  `}
`

export const Error = styled.small`
  margin-top: 0.3rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.2em;
  color: #d32f2f;

  ${props =>
    props.errorStyle === "login" &&
    `
    margin: 0;
    padding: 0.3rem 1rem;
    background-color: #D32F2F;
    color: white;
    text-align: left;
  `};
`

export const HelpText = styled.small`
  margin-top: 0.4rem;
  display: block;
  font-size: 1.2rem;
  line-height: 1.3em;
  opacity: 0.9;

  a {
    color: ${props => props.theme.colors.primary};
  }
`

export const FilterPill = styled.span`
  width: 4rem;
  text-align: center;
  font-size: 1.8rem;
  height: 3rem;
  background-color: ${props =>
    props.active ? grayBackgroundHover : grayBackground};
  background-color: ${props =>
    props.active ? grayBackgroundHover : grayBackground};
  display: inline-block;
  margin-left: 0.5rem;
  border-radius: 3px;
  color: ${primaryHeadingColor};
  &:hover {
    pointer: cursor;
  }
`

export const AnchorTag = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
`

// not currently used
export const ActiveIcon = styled.i`
  color: ${props => (props.active ? props.theme.colors.primary : "inherit")};
`

export const TooltipIcon = styled.span`
  margin-top: -2rem;
  border-radius: 3px;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-color: ${grayBackground};
  background-image: url(${tooltip});
  background-position: center center;
  background-size: 4px 9px;

  &:hover {
    background-image: url(${tooltipHover});
    transition: 0.2s all ease-in;
    background-color: ${props => props.theme.colors.primary};
    z-index: ${zIndex.tooltip};
  }
`
