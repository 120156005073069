import React from "react"
import Link from "shared/components/Link"
import { useQuery } from "@apollo/client"
import FETCH_RUNNING_CAMPAIGNS from "cartwright/graphql/FetchRunningCampaigns.graphql"
import styled from "styled-components"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import HorizontalRule from "shared/components/HorizontalRule"

const Card = styled(Link)`
  display: block;
  text-decoration: none;
  margin: 2rem 0;
  color: #535353;
  transition: 0.2s all ease-in-out;
  em {
    display: block;
  }
  &:hover {
    padding-left: 0.8rem;
  }
`

const CampaignCard = ({ campaign }) => {
  const { id, name, mechanism, account } = campaign
  return (
    <Card to={`/accounts/${account.id}/campaigns/${id}`}>
      <strong>{name}</strong> &middot; {mechanism}
      <em>{account.name}</em>
    </Card>
  )
}

const CurrentlyRunningCampaigns = () => {
  const { loading, data } = useQuery(FETCH_RUNNING_CAMPAIGNS)

  if (loading) {
    return null
  }

  return (
    <Box paddingTop="medium" paddingBottom="medium">
      <Heading level={1}>Currently running campaigns</Heading>
      {data &&
        data.campaigns.edges.map(campaign => (
          <React.Fragment key={campaign.node.id}>
            <CampaignCard campaign={campaign.node} />
            <HorizontalRule />
          </React.Fragment>
        ))}
      {data && data.campaigns.edges.length == 0 && (
        <Box paddingTop="small">
          <Text>No campaigns currently running</Text>
        </Box>
      )}
    </Box>
  )
}

export default CurrentlyRunningCampaigns
