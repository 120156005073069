import React from "react"
import Link from "shared/components/Link"
import Box from "shared/components/Box"
import styled from "styled-components"

const Container = styled(Link)`
  text-decoration: none;
  display: block;
  background: white;
  padding: 1.5rem 2rem;
  color: #535353;
  border-top: 2px solid #eee;

  &:hover {
    background: #efefef;
  }

  span {
    font-weight: normal;
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  li + li {
    margin-top: 0.5rem;
  }
`

const LearnerResultCard = ({ learner, to, showAccount }) => {
  if (showAccount) {
    return (
      <Container to={to}>
        <Box
          display={{ desktop: "flex" }}
          justifyContent={{ desktop: "space-between" }}>
          <Box>
            <ul>
              <li>
                <strong>
                  {learner.name} - {learner.email}
                </strong>
              </li>
              <li>{learner.accountName}</li>
              {learner.retired && <li>Retired</li>}
              {learner.samlNameId && <li>SAML ID: {learner.samlNameId}</li>}
              {learner.customIdentifier && (
                <li>Custom Identifier: {learner.customIdentifier}</li>
              )}
            </ul>
          </Box>
        </Box>
      </Container>
    )
  } else {
    return (
      <Container to={to}>
        <strong>{learner.name}</strong>
        <em> - {learner.email}</em>
      </Container>
    )
  }
}
export default LearnerResultCard
