import styled from "styled-components"
import { lighten } from "polished"
import colors from "shared/utils/colors"
import { sm } from "mill/utils/breakpoints"

export const MediaSelection = styled.div`
  display: inline-block;
  margin: 1.5rem 0 0.5rem;
  padding: 1.2rem 1.5rem;
  border: 2px solid ${colors.graySolitude};
  border-radius: 3px;
  font-size: 1.6rem;
  color: ${colors.grayMatterhorm};
  text-align: center;
  min-width: 16rem;
  font-weight: 500;
  flex: 1;

  i {
    margin-right: 1rem;
  }

  ${props =>
    props.active &&
    `
    background-color: ${lighten(0.5, props.theme.colors.primary)};
    border: 2px solid ${props.theme.colors.primary};

    i {
      color: ${props.theme.colors.primary};
    }
  `}

  @media (min-width: 400px) {
    &:first-of-type: {
      margin-right: 1rem;
    }
  }

  @media ${sm} {
    min-width: 20rem;
  }
`
