import styled from "styled-components"
import colors from "shared/utils/colors"

export const AccountCard = styled.div`
  width: 100%;
  border-radius: 0.2rem;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.04);
  border-top: 1px solid ${colors.grayWhiteSmoke};
  letter-spacing: 0.03em;
  margin-bottom: 2rem;
  font-size: 1.5rem;
`

export const AccountDetails = styled.div`
  min-height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 2rem;

  div {
    margin: 0.5rem 0;

    h2 {
      color: ${colors.grayMatterhorn};
      font-size: 1.8rem;
      padding: 0;
      margin: 0;
      font-weight: 500;
    }
  }
`

export const QuestionsStat = styled.p`
  display: inline-block;
  margin: auto 4rem auto 0;
  color: ${colors.grayAluminium};
  align-items: center;
  font-size: 1.5rem;

  strong {
    font-size: 1.8rem;
    font-weight: 500;
    color: ${colors.grayDim};
  }
`
