import React from "react"
import { bool } from "prop-types"
import styled from "styled-components"
import tickImg from "mill/components/Tick/tick@2x.png"

const Container = styled.div`
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  background: white;
  width: 3rem;
  height: 3rem;
  display: inline-block;
  margin-right: 1rem;

  ${props =>
    props.positionAbsolute &&
    `
    position: absolute;
    top: 1.5rem;
    left: 2.5rem;
  `}
`

const Check = styled.div`
  background-image: url(${tickImg});
  background-size: 20px 14px;
  background-repeat: no-repeat;
  width: 2rem;
  height: 1.4rem;
  margin: 0.6rem auto 0 auto;
`

const Tick = ({ absolute, ticked }) => {
  return (
    <Container positionAbsolute={absolute}>{ticked && <Check />}</Container>
  )
}

Tick.propTypes = {
  ticked: bool.isRequired
}

Tick.defaultProps = {
  ticked: false
}

export default Tick
