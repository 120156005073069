import React from "react"
// import Link from "shared/components/Link"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import Text from "shared/components/Text"
import Link from "shared/components/Link"
import FETCH_FEEDBACKS from "cartwright/graphql/FetchFeedbacks.graphql"

const Blockquote = styled.blockquote`
  padding: 1rem 0 1rem 2rem;
  border-left: 3px solid ${props => props.theme.colors.primary};
  margin: 2rem 0;

  cite {
    padding-top: 0.5rem;
    font-style: normal;
  }

  a {
    color: #767676;
  }
`

const RecentFeedback = () => {
  const { loading, data } = useQuery(FETCH_FEEDBACKS, {
    variables: {
      sources: ["platform", "question"],
      first: 10
    }
  })

  if (loading) {
    return null
  }

  return (
    <Box paddingTop="medium" paddingBottom="medium">
      <Heading level={1} bold>
        Recent Feedback
      </Heading>
      {data &&
        data.feedbacks.edges.map(feedback => {
          return (
            <Blockquote key={feedback.node.id}>
              <Text>
                <em>{feedback.node.feedback}</em>
              </Text>
              <cite>
                <Link
                  to={`/accounts/${feedback.node.user.accountId}/learners/${feedback.node.user.id}`}>
                  {feedback.node.user.name}
                </Link>{" "}
                &middot;{" "}
                <Link to={`/accounts/${feedback.node.user.accountId}/feedback`}>
                  {feedback.node.user.accountName}
                </Link>
              </cite>
            </Blockquote>
          )
        })}
    </Box>
  )
}

export default RecentFeedback
