import React from "react"
import { Field } from "formik"
import { useFormikContext } from "formik"
import { useMutation } from "@apollo/client"
import { useDropzone } from "react-dropzone"
import Box from "shared/components/Box"
import Label from "mill/components/Label"
import Button from "shared/components/Button"
import { FormikTextField as TextField } from "mill/components/TextField"
import CREATE_PRESIGNED_URL from "mill/graphql/CreatePresignedUrl"
import uploadToSignedURL from "mill/utils/uploadToSignedUrl"
import { Container, FieldContainer, Hidden } from "./styles"

const ImageURL = ({ label, fieldName, changeFieldValue }) => {
  const [createPresignedUrl] = useMutation(CREATE_PRESIGNED_URL, {})
  const { touched, errors } = useFormikContext()

  const handleImageUpload = url => {
    changeFieldValue(fieldName, url)
  }

  const onDrop = files => {
    files.forEach(file => {
      const mutation = createPresignedUrl({
        variables: {
          filename: file.name,
          filetype: file.type
        }
      })
      mutation.then(data => {
        uploadToSignedURL(
          file,
          data.data.createPresignedUrl.privateUrl,
          data.data.createPresignedUrl.publicUrl,
          handleImageUpload
        )
      })
    })
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
      "image/gif": []
    }
  })

  const handleUploadImageClick = e => {
    e.preventDefault()
    open()
  }

  const hasError = touched?.[fieldName] && errors?.[fieldName]

  return (
    <Container>
      <Box paddingBottom="xsmall">
        <Label hasError={hasError} title={label || "Image URL"} />
      </Box>
      <div>
        <Hidden {...getRootProps()}>
          <input {...getInputProps()} />
        </Hidden>

        <FieldContainer>
          <Field
            name={fieldName}
            data-qa="image-url"
            type="text"
            helpText="1200px × 400px optimal size"
            component={TextField}
            placeholder="Add image URL"
          />
        </FieldContainer>
        <Box display={{ mobile: "none", desktop: "flex" }}>
          <Box
            paddingLeft="small"
            paddingRight="small"
            paddingTop="xsmall"
            display="inline-block">
            <span style={{ whiteSpace: "nowrap" }}>or</span>
          </Box>
          <Box display="inline-block">
            <Button
              onClick={handleUploadImageClick}
              role="button"
              color="primary"
              variant="hollow"
              label="Upload image"
            />
          </Box>
        </Box>
      </div>
    </Container>
  )
}

export default ImageURL
