import React from "react"
import styled from "styled-components"

const Formgroup = styled.div`
  border: 0;
  padding: 0;
  margin-top: ${props => (props.marginTop ? `${props.marginTop}rem` : "2rem")};

  ${props =>
    props.inline &&
    `
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  `} ${props =>
    props.padding &&
    `
    input, button, label, span {
      margin-right: ${props.padding}rem;
    }
  `};

  > p {
    margin: 0 0 1.8rem;
  }
`

const FormGroup = props => {
  return <Formgroup {...props}>{props.children}</Formgroup>
}

export default FormGroup
