import React, { Fragment, useState } from "react"
import styled from "styled-components"
import { omit } from "lodash"
import { useMutation, useQuery } from "@apollo/client"
import { useParams, Navigate } from "react-router-dom"
import Box from "shared/components/Box"
import Button from "shared/components/Button"
import ThemeForm from "cartwright/components/ThemeForm"
import PlatformNavigation from "cartwright/components/Platform/PlatformNavigation"
import { Container, Sidebar, Content } from "cartwright/components/Layout"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import useNotifications from "shared/hooks/useNotifications"

import CREATE_THEME from "cartwright/graphql/CreateTheme"
import FETCH_THEMES from "cartwright/graphql/FetchThemes"
import FETCH_THEME from "cartwright/graphql/FetchTheme"

const Errors = styled.div`
  color: ${props => props.theme.colors.error};
  font-weight: bold;
`

const ThemeSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  primaryColor: Yup.string().required("Required"),
  secondaryColor: Yup.string().required("Required"),
  tertiaryColor: Yup.string().required("Required")
})

const PlatformThemes = (props) => {
  const [redirect, enableRedirect] = useState(false)
  const { themeId } = useParams()
  const { displayNotification } = useNotifications()

  const [createTheme, { data }] = useMutation(CREATE_THEME, {
    onCompleted: () => {
      displayNotification({
        style: "success",
        text: "Theme created",
      })
      enableRedirect(true)
    },
  })

  const { data: allThemes } = useQuery(FETCH_THEMES)
  const defaultThemeId =
    allThemes && allThemes.themes.find((t) => t.name == "yarno").id

  const { data: defaultTheme, loading: defaultThemeLoaded } = useQuery(
    FETCH_THEME,
    {
      variables: {
        themeId: defaultThemeId,
      },
      skip: !defaultThemeId,
    }
  )

  if (!defaultTheme) {
    return null
  }

  if (redirect)
    return (
      <Navigate to={`/platform/themes/${data.createTheme.theme.id}/edit`} />
    )

  const initialValues = omit({ ...defaultTheme.theme, name: "" }, [
    "id",
    "__typename",
  ])

  return (
    <Fragment>
      <Box>
        <h1>Platorm Reports</h1>
      </Box>
      <Container>
        <Sidebar>
          <PlatformNavigation />
        </Sidebar>
        <Content>
          <h1>New theme</h1>
          <Formik
            validationSchema={ThemeSchema}
            initialValues={initialValues}
            onSubmit={(values) => {
              return createTheme({
                variables: {
                  themeId: themeId,
                  attributes: { ...values },
                },
              })
            }}>
            {({ errors }) => {
              const errorKeys = Object.keys(errors)
              const hasErrors = !(errorKeys.length === 0)
              return (
                <Form>
                  <label>Theme name:</label>
                  <Field
                    name="name"
                    type="text"
                    style={{ marginLeft: "0.5rem" }}
                  />
                  <ThemeForm />
                  {hasErrors && (
                    <Errors>
                      The following colours are missing:
                      <ul>
                        {errorKeys.map((key, index) => (
                          <li key={index}>{key}</li>
                        ))}
                      </ul>
                    </Errors>
                  )}
                  <Button
                    label="Create theme"
                    type="submit"
                    disabled={hasErrors}
                    color="secondary"
                  />
                </Form>
              )
            }}
          </Formik>
        </Content>
      </Container>
    </Fragment>
  )
}

export default PlatformThemes
