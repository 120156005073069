import React from "react"
import styled from "styled-components"
import { Field } from "formik"

const FieldGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: inline-block;
    font-size: 1.8rem;
  }

  span {
    font-size: 1.3rem;
    display: block;
    margin-left: 5rem;
  }

  input {
    margin-right: 0.5rem;
  }
`

const Fieldset = styled.fieldset`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 4px solid #d7dadb;
  padding: 2rem;
  position: relative;

  legend {
    padding: 1rem;
    font-size: 1.8rem;
  }

  button {
    position: absolute;
    top: 5rem;
    right: 3rem;
  }

  > div {
    vertical-align: top;

    p {
      margin-top: 0;
    }
  }

  small {
    display: block;
    width: 30rem;
    margin-bottom: 2rem;
  }
`

const ThemeForm = () => {
  return (
    <Fieldset>
      <legend>Custom branding colours</legend>
      <FieldGroup>
        <Field name="primaryColor" type="color" />
        <label htmlFor="primaryColor">Primary colour</label>
        <span>The primary colour used throughout the platform</span>
      </FieldGroup>

      <FieldGroup>
        <Field name="secondaryColor" type="color" />
        <label htmlFor="secondaryColor">Secondary colour</label>
        <span>The secondary colour used throughout the platform</span>
      </FieldGroup>

      <FieldGroup>
        <Field name="tertiaryColor" type="color" />
        <label htmlFor="tertiaryColor">Tertiary colour</label>
        <span>
          This colour is used mainly in charts and occasionally in cards
        </span>
      </FieldGroup>
    </Fieldset>
  )
}

export default ThemeForm
