import React from "react"
import styled from "styled-components"
import { bodyFont } from "mill/utils/settings"
import { labelColor, paragraphColor } from "mill/utils/colors"

const Label = styled.label`
  font-family: ${bodyFont};
  font-size: ${props => (props.checkboxLabel ? "1.6rem" : "1.8rem")};
  color: ${props => (props.checkboxLabel ? paragraphColor : labelColor)};
  letter-spacing: 0;
  display: block;
  ${props => !props.checkboxLabel && `margin-bottom: 4px`} ${props =>
    props.checkboxLabel &&
    `
    line-height: 2.4rem;
  `};
`
const Title = styled.span`
  display: block;

  ${props =>
    props.labelStyle == "larger" &&
    `
    font-size: 2.2rem;
    `}
  ${props =>
    props.labelStyle == "important" &&
    `
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.3rem;
  `} ${props =>
    props.labelStyle == "important" &&
    props.hasChildren &&
    `
    margin-bottom: 0rem;
  `} ${props =>
    props.labelStyle == "login" &&
    `
    color: white;
    text-align: left;
    font-size: 1.8rem;
    margin-bottom: 0.3rem;
    font-weight: 400;
  `} ${props =>
    props.hasError &&
    `
    color: #D32F2F;
  `} ${props =>
    props.labelStyle == "login" &&
    props.hasError &&
    `
    color: white;
  `};
`

const Description = styled.span`
  margin-bottom: 0.4rem;
  display: block;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${paragraphColor};
`

const component = props => {
  return (
    <Label
      htmlFor={props.forInput || props.htmlFor}
      checkboxLabel={props.checkboxLabel}
      style={props.style}>
      <Title
        larger={props.larger}
        labelStyle={props.labelStyle}
        hasChildren={props.children}
        hasError={props.hasError}>
        {props.title}
      </Title>

      {props.children && <Description>{props.children} </Description>}
    </Label>
  )
}

export default component
