import React from "react"
import Link from "shared/components/Link"
import styled from "styled-components"

const Container = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  background: white;
  padding: 1.5rem 2rem;
  color: #535353;
  border-top: 2px solid #eee;

  ul {
    list-style-type: none;
    margin: 0;
  }

  li + li {
    margin-top: 0.5rem;
  }

  &:hover {
    background: #efefef;
  }
`

const CampaignResultCard = ({ campaign }) => {
  return (
    <Container to={`/accounts/${campaign.account.id}/campaigns/${campaign.id}`}>
      <ul>
        <li>
          <strong>{campaign.name}</strong>
        </li>
        {campaign.account.name && <li>{campaign.account.name}</li>}
        <li>Start Date: {campaign.friendlyStartDate}</li>
      </ul>
    </Container>
  )
}
export default CampaignResultCard
