import styled from "styled-components"
export const Container = styled.div`
  width: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  max-height: 40rem;

  ${props =>
    props.clickable &&
    `
  cursor: pointer;
  &:hover {
    i {
      opacity: 0.8;
    }
  }
  `}

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    font-size: 5rem;
    color: white;
    cursor: pointer;
  }
`
