import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import colors from "shared/utils/colors"
import NotificationContext from "shared/utils/NotificationContext"
import useNotifications from "shared/hooks/useNotifications"
import zIndex from "shared/utils/zIndexLevels"

const SnackbarContainer = styled.div`
  position: fixed;
  bottom: ${props => props.bottom};
  left: 0;
  right: 0;
  width: 100%;
  z-index: ${zIndex.obnoxious};
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`

const SnackbarInner = styled.div`
  overflow: hidden;
  padding: 0 2rem;
  background-color: ${props => props.theme.colors.error};
  color: white;
  max-height: 0;
  transition: 0.2s max-height ease-out;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  font-size: 1.4rem;

  ${props =>
    props.notificationStyle === "success" &&
    `
      background: ${colors.greenLightSea};
      color: white;
  `} ${props =>
    props.active &&
    `
      line-height: 5rem;
      height: 5rem;
      max-height: 5rem;
      transition: 0.2s max-height ease-in;
  `};
`

const SnackbarComponent = ({ notification, index }) => {
  const { id, text, style } = notification
  const { cancelNotification } = useNotifications()
  const [isActive, setActiveState] = useState(false)
  useEffect(() => {
    setActiveState(true)

    setTimeout(() => {
      cancelNotification(id)
    }, 5000)
  }, [])

  const bottomPosition = `${index * 55}px`

  return (
    <SnackbarContainer bottom={bottomPosition}>
      <SnackbarInner notificationStyle={style} active={isActive}>
        {text}
      </SnackbarInner>
    </SnackbarContainer>
  )
}

const Snackbar = () => {
  const { notifications } = useContext(NotificationContext)
  return notifications.map((notification, index) => {
    return (
      <SnackbarComponent
        key={notification.id}
        index={index}
        notification={notification}
      />
    )
  })
}

export default Snackbar
