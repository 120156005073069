import React from "react"
import styled from "styled-components"
import { darken } from "polished"
import Box from "shared/components/Box"
import Heading from "shared/components/Heading"
import colors from "shared/utils/colors"
import { find } from "lodash"

export const Answer = styled.p`
  background: white;
  border: 1px solid #f8f8f8;
  box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.03);
  border-radius: 2px;
  margin: 2rem 0;
  color: ${colors.grayDim};
  padding: 2rem 2rem 2rem 0;
  ${props =>
    props.isCorrectAnswer &&
    `
    background: rgba(32,171,150,0.07);
  `}

  span {
    padding: 2rem;
    margin-right: 2rem;
    box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.04);
    border-radius: 2px;
    ${props =>
      props.isCorrectAnswer &&
      `
      color: ${props => darken(0.1, props.theme.colors.primary)};
      background: rgba(32,171,150,0.03);
      font-weight: 600;
    `}
  }
`

const QuestionAnswers = ({ answers, answerDistributions }) => {
  const renderAnswer = id => {
    const answer = find(answers, { id: id })
    const isCorrectAnswer = answer.isCorrect
    const answerDistribution = find(answerDistributions, {
      answerId: id
    })

    return (
      <Answer isCorrectAnswer={isCorrectAnswer} key={answer.id}>
        <span>{answerDistribution.distributionPercentage}%</span>
        {answer.answer}
      </Answer>
    )
  }

  return (
    <Box marginTop="medium" marginBottom="large">
      <Heading level={2}>Answers</Heading>
      {answers.map(answer => renderAnswer(answer.id))}
    </Box>
  )
}

export default QuestionAnswers
