import React from "react"
import styled from "styled-components"

const HR = styled.hr`
  border: 0;
  margin: 0;
  padding: 0;
  background: none;

  border-top-width: ${props => props.lineWidth};
  border-top-style: ${props => props.lineStyle};
  border-top-color: ${props => props.color};
`

const HorizontalRule = ({ ...props }) => {
  return <HR {...props} />
}

export default HorizontalRule

HorizontalRule.defaultProps = {
  color: "#EAEAEA",
  lineStyle: "dashed",
  lineWidth: "1px"
}