import React from "react"
import { Container, NavItem } from "cartwright/utils/sharedStyles.js"

const PlatformNavigation = () => {
  return (
    <Container>
      <NavItem end to={"/platform/changelog"}>
        Changelog
      </NavItem>
      <NavItem end to={"/platform/engagement"}>
        Account engagement
      </NavItem>
      <NavItem end to={"/platform/off-the-shelf"}>
        Off the shelf
      </NavItem>
      <NavItem end to={"/platform/leaderboards"}>
        Leaderboards
      </NavItem>
      <NavItem end to={"/platform/reports"}>
        Reports
      </NavItem>
      <NavItem end to={"/platform/themes"}>
        Themes
      </NavItem>
      <NavItem end to={"/platform/feature-announcements/new"}>
        New feature Announcement
      </NavItem>
      <NavItem end to={"/platform/fixtures"}>
        Test fixtures
      </NavItem>
      <NavItem end to={"/platform/authentication-events"}>
        Authentication events
      </NavItem>
    </Container>
  )
}

export default PlatformNavigation
