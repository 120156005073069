import React from "react"
import styled from "styled-components"

const sizes = {
  1: "3.4rem",
  2: "2.55rem",
  3: "1.989rem",
  4: "1.7rem",
  5: "1.411rem",
}

const weights = {
  lighter: "300",
  light: "400",
  medium: "500",
  bold: "600"
}

const StyledHeading = styled.h1`
  font-family: Raleway, sans-serif;
  margin: 0;
  color: #30454b;

  ${({ capitalize }) =>
    capitalize &&
    `&:first-letter {
      text-transform: capitalize;
    }`}
  ${props =>
    props.color &&
    props.theme.colors[props.color] &&
    `color: ${props.theme.colors[props.color]};`}
  ${props => props.weight && `font-weight: ${weights[props.weight]};`}
  ${props => props.align && `text-align: ${props.align};`}
  ${props => props.size && `font-size: ${sizes[props.size]};`}
`

const Heading = ({ children, ...props }) => {
  return (
    <StyledHeading as={`h${props.level}`} {...props}>
      {children}
    </StyledHeading>
  )
}

export default Heading

Heading.defaultProps = {
  weight: "bold",
  level: "1"
}
