import styled from "styled-components"
import Box from "shared/components/Box"
import { sm, md } from "mill/utils/breakpoints"

export const Filters = styled(Box)`
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr;

  @media ${sm} {
    grid-template-columns: 1fr 1fr;
    align-items: center;

    > div:first-of-type {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > div:nth-of-type(2) {
      grid-column-start: 1;
      grid-column-end: 2;
    }

    > div:nth-of-type(3) {
      grid-column-start: 2;
      grid-column-end: 3;
    }
  }

  @media ${md} {
    grid-template-columns: 3fr 2fr 20rem;
    > div:first-of-type,
    > div:nth-of-type(2),
    > div:nth-of-type(3) {
      grid-column-start: auto;
      grid-column-end: auto;
    }
  }
`
