import styled from "styled-components"

export const Error = styled.div`
  background: ${props => props.theme.colors.error};
  color: white;
  padding: 1rem;
  border-radius: 4px;
`

export const FieldGroup = styled.div`
  label {
    display: block;
  }
  input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 1rem;
  }

  span {
    color: ${props => props.theme.colors.error};
  }
`