import React from "react"
import styled from "styled-components"
import { useLazyQuery } from "@apollo/client"
import FETCH_REPORT from "cartwright/graphql/FetchReport"

const Container = styled.div`
  text-decoration: none;
  display: block;
  background: white;
  padding: 1.5rem 2rem;
  color: #535353;
  border-top: 2px solid #eee;

  &:hover {
    background: #efefef;
    cursor: pointer;
  }

  strong,
  em {
    display: block;
  }

  pre {
    font-size: 1.3rem;
  }
`

const ReportResultCard = ({ report }) => {
  const [fetchReport] = useLazyQuery(FETCH_REPORT, {
    variables: { id: report.id },
    onCompleted: data => {
      window.open(data.report.downloadUrl)
    }
  })

  return (
    <Container onClick={fetchReport}>
      <strong>{report.friendlyName}</strong>
      <small>
        Created on {report.generatedAt} by {report.creator.name}
      </small>
      <pre>{report.args}</pre>
    </Container>
  )
}
export default ReportResultCard
