import React from "react"
import { Field, FieldArray, useFormikContext } from "formik"
import Label from "mill/components/Label"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import { FormikTextAreaField as TextArea } from "mill/components/TextArea"
import { Error } from "mill/utils/sharedStyles"
import { InputWrapper } from "./styles"

const AnswerField = ({ index }) => {
  const { values, errors, touched, setFieldValue, validateForm } =
    useFormikContext()

  const incorrectAnswers = values.incorrectAnswers
  const answer = incorrectAnswers[index]

  const renderAnswerError = () => {
    if (
      errors?.incorrectAnswers &&
      errors.incorrectAnswers[index] &&
      touched?.incorrectAnswers &&
      touched.incorrectAnswers[index]
    ) {
      return (
        <div>
          <Error>{errors.incorrectAnswers[index].answer}</Error>
        </div>
      )
    }
  }

  return (
    <Box id={`qa--answer-${index}`}>
      <Box position="relative" alignItems="center">
        <Field name={`incorrectAnswers.${index}.id`} type="hidden" />
        <InputWrapper>
          <Field
            autogrow
            id={`incorrect_answer_${index}`}
            name={`incorrectAnswers.${index}.answer`}
            withFieldSet
            fullWidth
            type="text"
            rows="1"
            component={TextArea}
            value={answer?.answer || ""}
          />
        </InputWrapper>
      </Box>
      {renderAnswerError(index)}
    </Box>
  )
}

const AnswerInput = props => {
  const {
    form: { values, touched, errors }
  } = props
  const incorrectAnswers = values.incorrectAnswers

  const hasIncorrectAnswerError =
    touched?.incorrectAnswers && errors?.incorrectAnswers

  return (
    <div>
      <Box paddingBottom="medium">
        <Box paddingTop="small">
          <InputWrapper>
            <Field
              autogrow
              id="correct_answer"
              label="Correct answer"
              description="
          Answer order is randomised when the question is presented to a
          learner."
              name="correctAnswer.answer"
              type="text"
              rows="1"
              fullWidth
              component={TextArea}
            />
          </InputWrapper>
        </Box>
      </Box>

      <Box>
        <Label title="Incorrect answers" hasError={hasIncorrectAnswerError}>
          Provide up to 3 incorrect answers.
        </Label>

        {hasIncorrectAnswerError && (
          <div>
            <Error>{errors.incorrectAnswers}</Error>
          </div>
        )}

        <AnswerField index={0} />
        <AnswerField index={1} />
        <AnswerField index={2} />
      </Box>
    </div>
  )
}

const QuestionAnswerInput = () => {
  return (
    <Box>
      <FieldArray name="answers" component={AnswerInput} />
    </Box>
  )
}

export default QuestionAnswerInput
