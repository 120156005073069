import React, { Fragment } from "react"
import Box from "shared/components/Box"
import Select from "shared/components/Select"
import FormGroup from "mill/components/FormGroup"
import Label from "mill/components/Label"
import { Error, HelpText } from "mill/utils/sharedStyles"

const FormSelect = props => {
  const {
    field,
    form: { touched, errors, setFieldValue, setFieldTouched },
    options,
    helpText,
    withFieldset,
    label,
    labelStyle,
    description,
    isMulti
  } = props

  const fieldHasErrors = touched[field.name] && errors[field.name]
  const Wrapper = withFieldset ? FormGroup : Fragment

  const handleChange = option => {
    const value = isMulti ? option.map(o => o.value) : option?.value
    setFieldTouched(field.name, true)
    setFieldValue(field.name, value)
  }

  const currentValue = () => {
    if (isMulti) {
      return options.filter(o => field.value.includes(o.value))
    } else {
      return options.find(o => o.value === field.value)
    }
  }

  return (
    <Wrapper>
      {label && (
        <Label hasError={fieldHasErrors} title={label} labelStyle={labelStyle}>
          {description && <Box paddingBottom="xsmall">{description}</Box>}
        </Label>
      )}
      <Select
        {...props}
        {...field}
        className={props.className || "select-control"}
        classNamePrefix="select-control"
        onChange={option => handleChange(option)}
        value={currentValue()}
      />
      {fieldHasErrors && <Error>{errors[field.name]}</Error>}
      {helpText && <HelpText>{helpText}</HelpText>}
    </Wrapper>
  )
}

export default FormSelect
