import React from "react"
import useVideoEmbed from "mill/hooks/useVideoEmbed"
import { VideoContainer } from "./styles"

const VideoFrame = ({ videoUrl, verticalVideo, verticalVideoResponsive }) => {
  const { embedUrl } = useVideoEmbed(videoUrl)

  return (
    <VideoContainer
      data-qa="video-frame"
      verticalVideo={verticalVideo === true}
      verticalVideoResponsive={verticalVideoResponsive === true}>
      {embedUrl && (
        <iframe
          width="100%"
          src={embedUrl}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
        />
      )}
    </VideoContainer>
  )
}
export default VideoFrame

VideoFrame.defaultProps = {
  verticalVideo: false,
  verticalVideoResponsive: false
}
