import { darken, lighten } from "polished"
import Box from "shared/components/Box"
import styled, { createGlobalStyle } from "styled-components"
import { md, lg, xl } from "mill/utils/breakpoints"

export const TippyStyles = createGlobalStyle`
  .tippy-box {
    .items {
      border-radius: 0.3125rem;
      background: #1d3841;
      box-shadow: 0 0 0 1px #0000000d, 0 10px 20px #0000001a;
      color: #FFF;
      overflow: hidden;
      padding: 0.2rem;
      position: relative;
    }

    .item {
      background: transparent;
      border: 2px solid transparent;
      border-radius: 0.4rem;
      display: block;
      margin: 0;
      padding: 0.2rem 0.4rem;
      text-align: left;
      width: 100%;
    }

    .item.is-selected {
      border-color: ${props => props.theme.colors.primary};
    }

    button {
      background: #2A4B55;
      border: none;
      color: #fff;
      font-size: 1.6rem;
      padding: 1rem 2rem;
    }
  }
`

export const ActivityPane = styled.div`
  ${props => (props.$open ? `display: block;` : `display: none;`)}
  background: #2a4b55;
  color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 36rem;
  padding: 0 2rem;

  div.ProseMirror {
    border-radius: 0.3125rem;
    background: #1d3841;
    padding: 0.5rem 4rem 0.5rem 2rem;
    line-height: 1.3;

    &:focus {
      outline: none;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.6em;
    }
  }

  .ProseMirror p.is-editor-empty:first-child:before {
    color: rgba(255, 255, 255, 0.3);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .ProseMirror blockquote {
    border-left: 3px solid ${darken(0.05, "#1d3841")};
    margin: 0 0 1rem 0;
    padding-left: 1rem;
  }

  @media ${md} {
    top: 8rem;
  }
`

export const Toggle = styled.button`
  ${props => (!props.$open ? `display: block;` : `display: none;`)}
  position: fixed;
  top: 10rem;
  right: 0;

  border-radius: 6px 0 0px 6px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  border: none;

  display: flex;
  flex-direction: column;

  &:hover {
    cursor: pointer;
  }

  svg {
    fill: ${darken(0.03, "#2A4B55")};
  }

  .comment {
    margin-top: 0.5rem;
  }

  &:hover {
    transition: 0.05s all ease-in;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  }

  @media ${md} {
    top: 22rem;
  }
`

export const FixedComments = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  top: 5rem;
  width: 36rem;
  padding: 1rem 1rem 2rem 2rem;

  ${props => (props.$open ? `display: grid;` : `display: none;`)}
  flex-direction: column;
  justify-content: space-between;
  grid-template-rows: 7rem 1fr;
  grid-template-columns: 1fr;

  & > div > h3 {
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
  }

  @media ${md} {
    top: 15rem;
    display: grid;
    grid-template-rows: 7rem 1fr;
  }
`

export const CommentContainer = styled.div`
  overflow-y: scroll;
  padding-right: 1rem;
  display: block;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }
  &::-webkit-scrollbar-track {
  }

  &::-webkit-scrollbar-thumb {
    background-color: #1d3841;
    outline: 1px solid #1d3841;
  }
  /*
  flex-direction: column;
  justify-content: flex-end;
  */

  & * {
    overflow-anchor: none;
  }
`

export const CommentHeader = styled.header`
  display: grid;
  grid-template-columns: 1fr 14rem;
  border-top: 2px solid ${darken(0.02, "#2A4B55")};
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  time {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.4);
  }

  > p {
    text-align: right;
  }

  > h4 {
    color: white;
    font-size: 1.3rem;
    font-family: "Open Sans", sans-serif;
  }
`

export const ActivityUpdateContainer = styled(Box)`
  border-top: 2px solid ${darken(0.02, "#2A4B55")};
  padding-top: 1rem;

  svg {
    fill: #496c78;
  }
  article {
    margin: 0.5rem 0;
    display: grid;
    grid-template-columns: 1.5rem 19rem 1fr;
    grid-column-gap: 1rem;
    align-items: center;

    p {
      font-size: 1.1rem;
      margin: 0;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  time {
    color: rgba(255, 255, 255, 0.4);
  }

  > p {
    text-align: right;
  }
`

export const CommentBody = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.4rem;

  p,
  ul,
  li,
  blockquote {
    font-size: 1.4rem;
  }

  p {
    margin: 0.5rem 0;
  }

  blockquote {
    border-left: 3px solid ${darken(0.04, "#2A4B55")};
    margin: 0 0 1rem 0;
    padding-left: 2rem;
  }
`

export const CommentReply = styled.button`
  float: right;
  border: none;
  color: ${props => props.theme.colors.primary};
  background: ${darken(0.02, "#2A4B55")};
  padding: 0.8rem 1rem;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1.1rem;
  margin-left: 1rem;
  &:hover {
    cursor: pointer;
  }
  svg {
    fill: ${props => props.theme.colors.primary};
  }
`

export const CloseIcon = styled.div`
  ${props => (props.$open ? `display: block;` : `display: none;`)}
  cursor: pointer;
`

export const CommentInput = styled.div`
  position: relative;
  button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: ${lighten(0.04, "#1d3841")};
    border: none;
    border-radius: 4px;
    padding: 1rem;
    cursor: pointer;
    svg {
      fill: ${props => props.theme.colors.primary};
    }

    &:hover {
      background: ${lighten(0.02, "#1d3841")};
      svg {
        fill: ${props => props.theme.colors.primary};
      }
    }
  }
`

export const Anchor = styled.div`
  overflow-anchor: auto;
  height: 1px;
`
