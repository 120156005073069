import React from "react"
import styled from "styled-components"
import FormGroup from "mill/components/FormGroup"
import Box from "shared/components/Box"
import Label from "mill/components/Label"
import { string } from "prop-types"

import { Error, HelpText } from "mill/utils/sharedStyles"
import { midGray, inputTextColor } from "mill/utils/colors"
import { inputFontSize, bodyFont } from "mill/utils/settings"

const AutoGrow = styled.div`
  ${props =>
    props.$autogrow &&
    `
    display: grid;

    textarea {
      min-height: unset;
      height: unset;
    }

    &::after {
      content: attr(data-replicated-value) " ";
      white-space: pre-wrap;
      visibility: hidden;
    }

    > textarea {
      resize: none;
      overflow: hidden;
    }

    &::after,
    > textarea {
      border-radius: 3px;
      border-color: ${midGray};
      border-width: 1px;
      border-style: solid;
      padding: 0.6rem 1.3rem;
      font: inherit;

      /* Place on top of each other */
      grid-area: 1 / 1 / 2 / 2;
    }
  `}
`

export const Area = styled.textarea`
  border-radius: 3px;
  border-color: ${midGray};
  border-width: 1px;
  border-style: solid;
  padding: 0.6rem 1.3rem;
  font-family: ${bodyFont};
  font-size: ${inputFontSize};
  line-height: 2.2rem;
  transition: all 0.2s ease-out;
  color: ${inputTextColor};
  min-height: 150px;
  display: block;

  ${props =>
    props.fullWidth &&
    `
    width: 100%;
  `} &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
    transition: all 0.2s ease-in;
    ${props =>
      props.hasError &&
      `
      border-color: #D32F2F;
    `};
  }

  ${props =>
    props.hasError &&
    `
    border-color: #D32F2F;
  `};
`

export const FormikTextAreaField = ({
  field,
  form: { touched, errors },
  helpText,
  HelpComponent,
  withFieldset,
  label,
  labelStyle,
  description,
  ...props
}) => {
  const accessorString = field.name
  const accessor = accessorString.split(".")

  /* This is programatic way to turn a nested field name eg. resourceLinks.0.text
   * in to usable values */
  let fieldTouched = touched
  for (let i = 0; i < accessor.length; i++) {
    if (fieldTouched && fieldTouched[accessor[i]]) {
      fieldTouched = fieldTouched[accessor[i]]
    } else {
      fieldTouched = false
    }
  }
  let fieldError = errors
  for (let i = 0; i < accessor.length; i++) {
    if (fieldError && fieldError[accessor[i]]) {
      fieldError = fieldError[accessor[i]]
    } else {
      fieldError = false
    }
  }

  const fieldHasErrors = fieldTouched && fieldError

  const contents = () => (
    <React.Fragment>
      {label && (
        <Label
          htmlFor={props.id}
          hasError={fieldHasErrors}
          title={label}
          labelStyle={labelStyle}>
          {description}
        </Label>
      )}
      <Box paddingTop={props.omitPaddingTop ? "" : "xsmall"}>
        <AutoGrow
          $autogrow={props.autogrow ? true : false}
          data-replicated-value={`${field?.value || " "}`}>
          <Area {...field} {...props} />
        </AutoGrow>
      </Box>
      {fieldHasErrors && <Error>{fieldError}</Error>}
      {helpText && <HelpText>{helpText}</HelpText>}
      {HelpComponent && <HelpComponent />}
    </React.Fragment>
  )

  return withFieldset ? (
    <FormGroup>{contents()}</FormGroup>
  ) : (
    <div>{contents()}</div>
  )
}

const TextArea = props => {
  const {
    meta: { touched, error },
    helpText,
    labelStyle
  } = props

  const renderContextualHelp = () => {
    return touched && error ? (
      <Error>{error}</Error>
    ) : helpText ? (
      <HelpText>{helpText}</HelpText>
    ) : null
  }

  const renderStandard = () => {
    return (
      <FormGroup>
        {props.label && (
          <Label
            htmlFor={props.id}
            hasError={touched && error}
            labelStyle={labelStyle}
            title={props.label}>
            {props.description}
          </Label>
        )}

        <AutoGrow $autogrow={props.autogrow ? true : false}>
          <Area
            hasError={touched && error}
            data-qa={props["data-qa"]}
            fullWidth={props.fullWidth}
            {...props.input}
            placeholder={props.placeholder || props.label}
            type={props.type}
            id={props.id}
          />
        </AutoGrow>

        {renderContextualHelp()}
      </FormGroup>
    )
  }

  return props.withFieldset ? (
    <FormGroup>{renderStandard()}</FormGroup>
  ) : (
    renderStandard()
  )
}

TextArea.propTypes = {
  label: string
}

export default TextArea
