import React from "react"
import { darken } from "polished"
import Box from "shared/components/Box"
import styled, { css, keyframes } from "styled-components"

export const RelativePositioningContainer = styled.div`
  position: relative;
  height: 5rem;
  width: 5rem;
`

export const Bell = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  position: relative;
  z-index: 10;
  text-decoration: none;

  background: none;
  font-size: 22px;
  padding: 1rem 0;
  text-align: center;
  i {
    color: #253337;
  }

  ${props =>
    (props.visible || props.hasUnreadNotifications) &&
    `
    i {
      color: ${props.theme.colors.primary};
    }
  `}
`

export const fadeInDown = keyframes`
  from {
    opacity: 0;
    max-height: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    max-height: initial;
    transform: translate3d(0, 0, 0);
  }
`

export const fadeOutUp = keyframes`
  from {
    max-height: initial;
    opacity: 1;
  }

  to {
    opacity: 0;
    max-height: 0;
    transform: translate3d(0, -100%, 0);
  }
`

export const Container = styled.div`
  position: relative;
  top: 4.7rem;
  width: 95vw;
  max-width: 31rem;
  background: #ffffff;
  box-shadow: 1px 2px 16px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  opacity: 0;
  max-height: 0;

  img {
    max-width: 100%;
  }

  ${props =>
    props.visible &&
    css`
      animation: ${fadeInDown} 300ms;
      max-height: inherit;
      opacity: 1;
    `}

  ${props =>
    props.visiblePreviously &&
    !props.visible &&
    css`
      animation: ${fadeOutUp} 300ms;
      max-height: 0;
      opacity: 0;
    `}
`

export const AbsolutelyPositionedContainer = styled.div`
  z-index: 1;
  position: absolute;
  right: -3rem;
  top: 0rem;
  z-index: 0;
  overflow: ${props => (props.visible ? `visible` : "hidden")};
`

export const ViewAll = styled(Box)`
  border-top: 1px solid #f8f8f8;
  background-image: url("https://yarno.imgix.net/static/invitation-letter-hand.png?&h=46&auto=format,compress");
  background-size: 77px 23px;
  background-position: calc(100% + 77px) 0.7rem;
  transition: background-position 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  background-repeat: no-repeat;
  &:hover {
    background-position: calc(100%) 0.7rem;
  }
  a {
    display: block;
    color: ${props => props.theme.colors.primary} !important;
    &:hover {
      ${props =>
        props.theme.colors.primary &&
        `color: ${darken(0.05, props.theme.colors.primary)} !important`};
    }
  }
`

export const NotificationContainer = styled.div`
  max-height: calc(100vh - 20rem);
  overflow-y: scroll;
`

export const UnreadCount = styled.span`
  background: ${props => props.theme.colors.primary};
  color: white !important;
  border-radius: 1.7rem;
  font-size: 0.9rem;
  font-weight: bold;
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.7rem;
  height: 1.7rem;
`

export const Header = styled.div`
  line-height: 1;
  position: relative;

  a {
    cursor: pointer;
    color: ${props => props.theme.colors.primary} !important;
    position: relative;
    top: -2px;
    &:hover {
      ${props =>
        props.theme.colors.primary &&
        `color: ${darken(0.05, props.theme.colors.primary)} !important`};
    }
  }

  &:before {
    content: " ";
    position: absolute;
    top: -4px;
    right: 4.8rem;
    height: 15px;
    width: 15px;
    border-radius: 3px 0px 0px 0px;
    transform: rotate(45deg);
    background: white;
  }
`

export const AllRead = styled.span`
  color: #c1c1c1;
  position: relative;
  top: -1px;
`
