import styled from "styled-components"

export const Container = styled.div`
  > div {
    display: flex;
  }

  input {
    height: 4rem;
    max-width: 100%;
    width: 100%;
  }

  > div > span {
    padding: 0 2rem;
    line-height: 4rem;
  }
`

export const FieldContainer = styled.div`
  flex: 1;
`
export const Hidden = styled.div`
  display: none;
`
