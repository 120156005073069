import styled, { css } from "styled-components"
import Link from "shared/components/Link"
import { lighten, darken } from "polished"
import getReadableFontColor from "mill/utils/getReadableFontColor"

export const baseStyles = css`
  border-radius: 3px;
  border-style: solid;
  letter-spacing: 0.6px;
  text-indent: 1px;
  font-family: "Raleway";
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  transition: all 0.1s ease-out;
  white-space: nowrap;

  &:hover {
    transition: all 0.1s ease-in;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:first-letter {
    text-transform: uppercase;
  }
`

const xSmallStyles = css`
  font-size: 1.2rem;
  border-width: 2px;
  padding: 0.4rem;
  img {
    padding-left: 0.4rem;
    height: 0.7rem;
    position: relative;
    top: -1px;
  }
`

const smallStyles = css`
  font-size: 1.2rem;
  border-width: 2px;
  padding: 0.6rem 1rem;
  img {
    padding-left: 0.6rem;
    height: 0.8rem;
    position: relative;
    top: -1px;
  }
`

const mediumStyles = css`
  font-size: 1.4rem;
  border-width: 2px;
  padding: 1.1rem 1rem;
  img {
    padding-left: 1rem;
    height: 1rem;
    position: relative;
    top: -1px;
  }
`

const largeStyles = css`
  font-size: 1.5rem;
  border-width: 2px;
  padding: 1.8rem;
  height: 6rem;
  min-width: 21rem;
  small {
    font-size: 1.2rem;
  }
  img {
    padding-left: 1.4rem;
    height: 1.3rem;
    position: relative;
    top: -2px;
  }
`

const filledStyles = css`
  ${props => `
    background: ${props.theme.colors[props.color]};
    border-color: ${props.theme.colors[props.color]};
    color: ${getReadableFontColor(
      props.theme.colors[props.color],
      props.theme.name
    )};
    &:hover {
      background: ${darken(0.1, props.theme.colors[props.color])};
      border-color: ${darken(0.1, props.theme.colors[props.color])};
      color: ${getReadableFontColor(
        props.theme.colors[props.color],
        props.theme.name
      )};
    }
    
    &:disabled, &[data-disabled="true"] {
      background: #D7DADB;
      border-color: #D7DADB;
      color: white;
      &:hover {
        cursor: not-allowed;
      }
    }
  `}
`

const textStyles = css`
  ${props => `
    background: transparent;
    border: none;
    color: ${props.theme.colors[props.color]};
    &:hover {
      border: none;
      color: ${darken(0.1, props.theme.colors[props.color])};
    }

    &:disabled, &[data-disabled="true"] {
      background: transparent;
      border-color: #D7DADB;
      color: #D7DADB;
      &:hover {
        cursor: not-allowed;
      }
    }
  `}
`

const hollowStyles = css`
  ${props => `
    background: transparent;
    border-color: ${props.theme.colors[props.color]};
    color: ${props.theme.colors[props.color]};
    &:hover {
      background: transparent;
      border-color: ${darken(0.1, props.theme.colors[props.color])};
      color: ${darken(0.1, props.theme.colors[props.color])};
    }

    &:disabled, &[data-disabled="true"] {
      background: transparent;
      border-color: #D7DADB;
      color: #D7DADB;
      &:hover {
        cursor: not-allowed;
      }
    }
  `}
`

export const StyledButton = styled.button`
  ${baseStyles}
  ${props => props.fullWidth && `display: block; width: 100%;`}

  ${props => props.size == "xsmall" && xSmallStyles}
  ${props => props.size == "small" && smallStyles}
  ${props => props.size == "medium" && mediumStyles}
  ${props => props.size == "large" && largeStyles}

  ${props => props.variant == "filled" && filledStyles}
  ${props => props.variant == "hollow" && hollowStyles}
  ${props => props.variant == "text" && textStyles}
  ${props => props.legacy && "text-transform: uppercase;"}
`

export const StyledButtonLink = styled(Link)`
  display: ${props => (props.fullWidth ? `block` : `inline-block`)};
  ${baseStyles}

  ${props => props.size == "xsmall" && xSmallStyles}
  ${props => props.size == "small" && smallStyles}
  ${props => props.size == "medium" && mediumStyles}
  ${props => props.size == "large" && largeStyles}

  ${props => props.variant == "filled" && filledStyles}
  ${props => props.variant == "hollow" && hollowStyles}
  ${props => props.variant == "text" && textStyles}
  ${props => props.legacy && "text-transform: uppercase;"}
`
