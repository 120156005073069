import styled, { keyframes } from "styled-components"
import Link from "shared/components/Link"
import { headingFont } from "mill/utils/settings"
import zIndex from "shared/utils/zIndexLevels"
import { sm, md, lg } from "../breakpoints"
import { darken } from "polished"
import colors from "shared/utils/colors"

export const Flex = styled.div`
  flex: 1;
  ${props =>
    props.backgroundColor &&
    `
    overflow: hidden;
    background-color: ${props.backgroundColor};
  `};
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${props =>
    props.formWidth ? "800px" : props.smaller ? "1200px" : "1400px"};
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  @media ${sm} {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  ${props => props.paddedBottom && `padding-bottom: 2rem;`} ${props =>
    props.paddedTop && `padding-top: 3rem;`} ${props =>
    props.paddedTopHalf && `padding-top: 1.5rem;`}

  ${props => props.noPad && `padding: 0 !important`}
`

export const Grid = styled.div`
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}
  display: flex;
  width: ${props => (props.noWidth ? `inherit;` : `100%;`)}
  ${props => (props.noHeight ? `height: inherit;` : `height: 100%;`)}
  ${props => (props.flow ? `flex-flow: ${props.flow};` : `flex-flow: row;`)}
  flex-flow: ${props => (props.flow ? props.flow : "row")};
  flex-wrap: ${props => (props.wrap ? "wrap" : "nowrap")};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "space-between"};
  ${props => props.maxWidth && `max-width: ${props.maxWidth};`}
  ${props => props.centerAlign && `margin: 0 auto;`}

  ${props =>
    props.gutters &&
    `
    margin: 0rem -1rem 0 -1rem;
  `};
`

export const GridCell = styled.div`
  flex: ${props => props.sz && `0 0 ${props.sz * 100}%`};
  ${props => !props.noPad && `padding: 0 1rem;`}
  max-width: 100%;

  ${props =>
    props.flex &&
    `
    display: flex;
  `}

  ${props =>
    props.sm &&
    `
    @media ${sm}{
      flex: 0 0 ${props.sm * 100}%;
      max-width: ${props.sm * 100}%;
    }
  `}

  ${props =>
    props.md &&
    `
    @media ${md}{
      flex: 0 0 ${props.md * 100}%;
      max-width: ${props.md * 100}%;
    }
  `}

  ${props =>
    props.lg &&
    `
    @media ${lg}{
      flex: 0 0 ${props.lg * 100}%;
      max-width: ${props.lg * 100}%;
    }
  `}
`

export const PanelImage = styled.div`
  text-align: center;
  overflow: hidden;

  ${props =>
    props.background &&
    `
    background-image: url(${props.background});
    background-position: top center;
  `} ${props =>
    props.height &&
    `
    height: ${props.height};
  `};
`

export const Well = styled.div`
  border-radius: 3px;
  border: 3px solid ${colors.graySolitude};
  padding: 2rem 3rem;
  margin-top: 3rem;
`

export const AlternateBackground = styled.div`
  background-color: ${colors.grayWhiteSmoke};
  overflow: hidden;
  flex: 1;
`

export const Hr = styled.hr`
  border: none;
  height: 0.3rem;
  background-color: ${colors.grayWhiteSmoke};
  ${props => props.noMargin && `margin: 0;`}
  ${props => props.smallMargin && `margin: 1.5rem 0;`}
  ${props => props.largeMargin && `margin: 4rem 0;`}
  ${props => props.hideSm && `display: none; @media ${lg} { display: block; }`};
`

export const BackBar = styled.div`
  background: #ededed;
  padding: 1.4rem 0;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 1.6rem;
  height: 5rem;

  a,
  a:hover {
    color: #7a8381;
  }
`
export const Pill = styled.span`
  display: inline-block;
  background: ${props => {
    if (props.inactive) return colors.grayAthens
    return props.color ? props.color : props.theme.colors.primary
  }};
  color: white;
  border-radius: 3px;
  margin-right: 1rem;
  padding 0.2rem 0.5rem;
  font-size: ${props => (props.small ? "0.9rem" : "1.1rem")};
  letter-spacing: 0.1rem;
  font-weight:bold;
  text-transform: uppercase;
`

export const Divider = styled.hr`
  height: 2px;
  background-color: ${colors.grayWhisper};
  width: 66%;
  border: none;
  margin: 2rem 0 2rem 0;
`

export const SplitContainer = styled.div`
  flex: 1;
  @media ${md} {
    display: flex;
    flex-flow: row-reverse;
  }
`

export const SplitHalf = styled.div`
  img {
    max-width: 100%;
  }

  p a {
    color: ${props => props.theme.colors.primary};
  }

  li {
    font-size: 1.6rem;
  }
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : "white"};
  ${props =>
    props.hasText &&
    `
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`};

  ${props =>
    props.hasImage &&
    `
  padding: 2rem;
  background-size: cover;
  text-align: center;
  ${
    props.backgroundImage &&
    `background-image: url(${props.backgroundImage}); background-position: center center;  min-height: 25rem;`
  }
`} ${props =>
    props.ratio &&
    `
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${props.ratio * 100}%;
    @media ${lg} {
      content: none;
    }
  }
`}
  img {
    max-width: 100%;
  }
  @media ${md} {
    padding: 4rem 3rem;
    flex: 1;
    ${props =>
      props.hasImage &&
      `
  display: flex;
  align-items: ${props.centerContent ? "center" : "flex-start"};
  justify-content: center;
  min-width: 50%;
  max-width: 50%;
  img {
    max-width: 100%;
  }

`} ${props =>
      props.hasText &&
      `
    > div {
      max-width: 50rem;
      margin: 0 auto;
    }
`};
  }
`

export const ActionableTitle = styled.p`
  font-family: ${headingFont};
  font-size: 1.6rem;
  letter-spacing: 1.48px;
  text-transform: uppercase;
`

export const ActionableWrapper = styled.div`
  margin-bottom: 4.8rem;
  @media ${md} {
    margin: 4rem 3rem;
  }
`

function fadeIn() {
  return keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
  `
}

export const ModalContainer = styled.div`
  position: fixed;
  backface-visibility: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: ${zIndex.modal};
  background: rgba(43, 46, 56, 0.9);
  display: flex;
  animation: ${fadeIn} 0.2s;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  padding: 6rem 1rem;
`

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 3rem;

  span {
    color: ${props => props.theme.colors.primary};
    text-decoration: unerline;
    display: block;
    line-height: 4rem;
    margin-top: 1.5rem;
    margin-right: 2rem;
    &:hover {
      cursor: pointer;
    }
  }

  button {
    max-width: 15rem;
  }
`

export const Modal = styled.div`
  background: white;
  flex-grow: 1;
  animation: ${fadeIn} 0.4s;
  max-width: ${props =>
    props.maxWidth ? props.maxWidth : props.fullWidth ? "100%" : "50rem"};
  border-radius: 3px;
  max-height: 100%;
  overflow-y: ${props => (props.justImage ? "visible" : "scroll")};
  ${props => props.withPadding && `padding: 3rem;`}

  img {
    max-width: 100%;
    max-height: 75vh;
    display: block;
    margin: 0 auto;
  }

  h1 {
    font-size: 2.4rem;
    line-height: 1.2em;
    margin-top: 0;
    margin-bottom: 1rem;
    font-family: ${headingFont};
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  button {
    display: block;
    width: 100%;
    margin-top: 1.5rem;
  }
`

export const ModalInner = styled.div`
  padding: 3rem;
`

export const ModalClose = styled.div`
  position: fixed;
  top: 0.5rem;
  right: 1.2rem;
  z-index: ${zIndex.modal + 1};
  font-size: 3rem;
  color: white;
  &:hover {
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
`

export const ContentDecorator = styled.div`
  a {
    color: ${props => props.theme.colors.primary};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 300;
    color: ${darken(0.15, "rgb(83, 83, 83)")};
    text-transform: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li {
    hyphens: manual;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 3.8rem;
    margin: 1.6rem 0;
    font-family: "Open Sans";
    letter-spacing: normal;
  }

  h2 {
    font-size: 2rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 1.8rem;
    line-height: 2.7rem;
  }

  h4 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  p,
  ul,
  li {
    font-size: 1.5rem;
    line-height: 1.5em;
  }

  ol {
    list-style: decimal;
  }
  ol ol {
    list-style: lower-latin;
  }
  ol ol ol {
    list-style: lower-roman;
  }

  li p {
    margin: 1rem 0;
  }

  .iframe-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 51%;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  iframe,
  img {
    max-width: 100%;
    border: none;
  }

  hr {
    border: none;
    height: 0.2rem;
    background-color: ${colors.graySolitude};
    margin: 3rem 0;
  }

  table {
    margin-top: 2rem;
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 0.5rem;
    }

    p,
    ul,
    ol {
      margin: 0.5rem 0;
    }
  }

  table,
  th,
  td {
    border: 1px solid ${colors.grayWhisper};
  }

  td {
    min-width: 100px;
  }

  pre {
    background-color: ${colors.graySolitude};
    padding: 1rem 2rem;
  }
`

export const LearningLibraryContentDecorator = styled(ContentDecorator)`
  h1,
  h2,
  h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    margin: 4.8rem 0 2.208rem;
    line-height: 1.5;
    color: #535353;
  }

  h1 {
    font-size: 2.2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.6rem;
  }

  ul,
  ol,
  li {
    font-size: 1.6rem;
    line-height: 1.5;

    p {
      margin: 0.8rem 0;
    }
  }

  img {
    margin: 1.6rem 0;
  }

  @media ${md} {
    h1,
    h2,
    h3 {
      padding-right: 2rem;
      line-height: 1.5;
    }

    h1 {
      font-size: 3.981rem;
    }

    h2 {
      font-size: 3.318rem;
    }

    h3 {
      font-size: 2.765rem;
    }

    h4 {
      font-size: 2.304rem;
    }

    p,
    ul,
    ol,
    li {
      font-size: 1.8rem;
      line-height: 1.5;
    }

    img {
      margin: 4rem 0;
    }
  }
`

export const Small = styled.p`
  font-style: italic;
  font-size: 1.5rem;
  margin-bottom: 3rem;
`

export const SecondaryLink = styled(Link)`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  font-weight: bold;
`

export const OutboundLink = styled.a`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
`
