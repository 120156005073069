/*
 * Core App Colors
 * ------------------------------------------------------------------------------------------
 * colorHover means the typical hover color for a state. Eg. A button with a green background
 * colour should almost always have a :hover background color of greenHover
 */

export const primaryHeadingColor = `rgb(112,112,112)`
export const disabledBackground = `rgb(215,218,219)`
export const disabledForeground = `rgb(173,173,173)`

export const inputTextColor = `rgb(142,144,145)`
export const labelColor = `rgb(48,69,75)`
export const paragraphColor = `rgb(132, 134, 136)`

export const green = "rgb(37,193,169)" // #25C1A9
export const greenHover = "rgb(32,171,150)" // #25C1A9

export const orange = "rgb(249,181,35)" // #F9B523
export const orangeHover = "rgb(230,167,32)" // #EAA90F

export const red = "rgb(190,114,114)" // #BE7272
export const redHover = "rgb(158,95,95)" // #9E5F5F

export const beige = "rgb(225,208,179)"
export const purple = "rgb(212,164,214)"

export const orange_dark = "rgb(234,169,15)" // #EAA90F

export const grayBackground = "rgb(248, 247,247)" // "rgb(231,236,238)" // #E7ECEE
export const grayBackgroundHover = "rgb(200,207,210)" // #E7ECEE
export const regularBackground = "rgb(255,255,255)" // #FFFFFF

export const horizontalRuleColor = "rgb(248,247,247)"
export const lightGray = "rgb(233,236,238)"
export const midGray = "rgb(185,185,185)"

export const libraryGray = "rgb(247,248,249)"
export const libraryBorder = "rgb(233,233,233)"
export const libraryTitle = "rgb(75,82,82)"
export const libraryTile = "rgb(250,251,252)"

export const burstBlue = "rgb(125,181,255)"
export const burstBlueBorder = "rgb(185,215,255)"

export const alternate = "rgb(247,247,247)"
export const paleBlue = "rgb(242,247,248)"
export const contrast = "rgb(64, 90, 97)"

export const errorBackground = "#ed6364"
