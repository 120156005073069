import styled, { keyframes } from "styled-components"
import Box from "shared/components/Box"
import zIndex from "shared/utils/zIndexLevels"
import { sm, md } from "mill/utils/breakpoints"

function fadeIn() {
  return keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
  `
}

export const Container = styled.div`
  position: fixed;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.modal};
  background: rgba(43, 46, 56, 0.9);
  animation: ${fadeIn} 0.2s;
  overflow: hidden;
  padding: 5rem 1rem;
`

// TO DO: forward a ref so this can be a styled(Box)
export const ModalInner = styled.div`
  background: #fff;
  min-width: 32rem;
  max-width: ${props =>
    props.maxWidth ? props.maxWidth : props.fullWidth ? "100%" : "45rem"};
  border-radius: 3px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: visible;

  img {
    max-width: 100%;
    max-height: 75vh;
    display: block;
    margin: 0 auto;
  }

  @media ${sm} {
    min-width: 40rem;
  }
`

export const Close = styled.i`
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2.4rem;
  cursor: pointer;
  color: ${props => props.theme.colors.error};
`

export const ButtonBox = styled(Box)`
  button {
    width: 100%;
  }
`