import React, { Fragment } from "react"
import Text from "shared/components/Text"
import Box from "shared/components/Box"
import { NotificationList, NotificationText, ReadMarking, Link } from "./styles"

const NotificationGroup = ({ title, notifications, handleReadToggle }) => {
  if (notifications.length == 0) {
    return null
  }

  return (
    <Fragment>
      <Box
        paddingLeft="medium"
        paddingRight="medium"
        paddingTop="small"
        paddingBottom="small"
        style={{ background: "#f3f4f3" }}>
        <Text size="msmall" weight="mstrong">
          {title}
        </Text>
      </Box>
      <NotificationList>
        {notifications.map(notification => {
          return (
            <Notification
              key={`notification-${notification.id}`}
              id={notification.id}
              url={notification.url}
              message={notification.message}
              timestamp={notification.createdAtInWords}
              read={notification.read}
              handleReadToggle={handleReadToggle}
            />
          )
        })}
      </NotificationList>
    </Fragment>
  )
}

const MaybeLink = ({ url, children }) => {
  if (url && url.length) {
    return <Link to={url}>{children}</Link>
  } else {
    return children
  }
}

const Notification = ({ id, message, url, timestamp, read, handleReadToggle }) => {
  return (
    <Box data-qa={`notification-${id}`}>
      <MaybeLink url={url}>
        <Box
          paddingLeft="medium"
          paddingRight="medium"
          paddingTop="small"
          paddingBottom="small"
          display="grid"
          gridTemplateRows="1fr"
          gridTemplateColumns="2rem 1fr">
          <ReadMarking read={read} onClick={() => handleReadToggle(id, !read) } />
          <NotificationText>
            <Text weight="mstrong" size="msmall" tone="mediumDark">
              {message}
            </Text>
            <Text size="xsmall" tone="neutral">
              {timestamp}
            </Text>
          </NotificationText>
        </Box>
      </MaybeLink>
    </Box>
  )
}

export default NotificationGroup