import React from "react"
import styled, { keyframes } from "styled-components"

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Rotate = styled.div`
  overflow: hidden;
  height: calc(100vh - 8rem);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    animation: ${rotation} 2s ease infinite;
  }
`

const RotatingMark = () => {
  return (
    <Rotate>
      <img
        src="https://yarno.imgix.net/static/customer-service-eggers.png?format=auto&h=500"
        alt="Spinny Mark"
      />
      <h1>Generating report</h1>
    </Rotate>
  )
}

export default RotatingMark
