import React from "react"
import styled from "styled-components"

const Container = styled.span`
  font-size: 1.2rem;
  color: white;
  border-radius: 10px;
  background: ${props => props.theme.colors[props.color || "primary"]};
  padding: 0.5rem 1.4rem;
  line-height: 1;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.saturate && `filter: saturate(${props.saturate});`}
`

const Pill = ({ children, ...props }) => {
  return <Container {...props}>{children}</Container>
}

export default Pill
