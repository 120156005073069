import React, { Component } from "react"
import { Container, Text, Value, Header, Subheader } from "./styles"

class BigNumberBlock extends Component {
  render() {
    return (
      <Container style={{ height: this.props.height + "px" }}>
        <Text>
          <Header>{this.props.header}</Header>
          {this.props.subheader && (
            <Subheader>{this.props.subheader}</Subheader>
          )}
        </Text>
        <Value>{this.props.value}</Value>
      </Container>
    )
  }
}

export default BigNumberBlock
