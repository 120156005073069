import React from "react"
import Pill from "shared/components/Pill"
import { useQuery } from "@apollo/client"
import styled from "styled-components"
import Loader from "shared/components/Loader"
import Button from "shared/components/Button"
import Box from "shared/components/Box"
import Text from "shared/components/Text"
import YarnoPill from "mill/components/YarnoPill"
import ClickableTopicList from "mill/components/Admin/ClickableTopicList"
import { List, ListItem } from "shared/containers/List"
import CursorPagination from "shared/components/CursorPagination"
import useCursorPagination from "shared/hooks/useCursorPagination"
import useFilters from "shared/hooks/useFilters"
import useQuestionPreview from "mill/hooks/useQuestionPreview"
import QuestionPreviewModal from "mill/components/QuestionPreviewModal"
import { Flex } from "mill/utils/shared/core"

const PreviewIcon = styled.i`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.2rem;
  &:hover {
    cursor: pointer;
  }
`

import FETCH_QUESTIONS from "mill/graphql/FetchQuestions"

const QuestionsTable = ({
  accountId,
  category,
  editQuestionPath,
  questionResultPath
}) => {
  const cursorPagination = useCursorPagination()
  const { getParamValue, handleClearFilters } = useFilters()
  const searchTerm = getParamValue("searchTerm")
  const topicIds = getParamValue("topicIds")
  const collectionIds = getParamValue("collectionIds")
  const expiredOnly = getParamValue("expiredOnly")
  const includeDraft = getParamValue("includeDraft")
  const includePublished = getParamValue("includePublished")
  const sortBy = getParamValue("sortBy")
  const { questionPreviewOpen, setPreviewable } = useQuestionPreview()

  const { loading, error, data, variables } = useQuery(FETCH_QUESTIONS, {
    variables: {
      ...cursorPagination,
      accountId: accountId,
      categories: [category],
      topicIds: topicIds || null,
      collectionIds: collectionIds || null,
      includeDraft: includeDraft && includeDraft === "true",
      includePublished: includePublished && includePublished === "true",
      searchTerm,
      sortBy
    }
  })

  if (error) {
    console.log({ error })
    return <div>Error</div>
  }

  const questions = data?.questions?.edges

  return (
    <Flex>
      {loading || !data ? (
        <Loader />
      ) : (
        <List data-qa="questions-table">
          {questions.length > 0 &&
            questions.map(question => {
              const topics = question.node.topics
              return (
                <ListItem
                  data-qa={`q-${question.node.id}`}
                  key={question.node.id}
                  padding="medium"
                  display="flex"
                  alignItems="center">
                  <Box paddingRight="large">
                    <PreviewIcon
                      data-qa={`preview-question-${question.node.id}`}
                      className="fa fa-eye-with-line"
                      onClick={() => {
                        setPreviewable(question.node.id, question.cursor)
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    flex="1"
                    justifyContent="space-between"
                    flexDirection={{ mobile: "column", desktop: "row" }}
                    alignItems={{ mobile: "flex-start", desktop: "center" }}>
                    <Box>
                      <Text display={"inline"} tone="dark" readableWidth>
                        {question.node.question}
                      </Text>
                      {!question.node.published && (
                        <Box display={"inline-block"} marginLeft="xsmall">
                          <Pill color={"primary"} saturate={"0%"}>
                            Draft
                          </Pill>
                        </Box>
                      )}
                      <ClickableTopicList topics={topics} />
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      // maxWidth="small"
                      paddingTop={{ mobile: "small", desktop: "none" }}
                      paddingLeft={{ desktop: "small" }}
                      textAlign="right">
                      {!question.node.editable && (
                        <Box
                          paddingBottom={{ mobile: "xsmall", tablet: "none" }}
                          paddingRight="xsmall">
                          <YarnoPill />
                        </Box>
                      )}
                      {question.node.editable && (
                        <Box paddingRight="xsmall">
                          <Button
                            label={"Edit question"}
                            to={editQuestionPath(question.node.id)}
                            color="primary"
                            variant="text"
                            size="xsmall"
                          />
                        </Box>
                      )}
                      {questionResultPath && (
                        <Button
                          label={"View results"}
                          to={questionResultPath(question.node.id)}
                          color="primary"
                          variant="hollow"
                          size="xsmall"
                        />
                      )}
                    </Box>
                  </Box>
                </ListItem>
              )
            })}

          {questions.length === 0 &&
            (searchTerm ? (
              <Box paddingTop="medium" paddingBottom="medium">
                <Text>
                  Your search didn't return any results. Please try a different
                  spelling or typing fewer characters!
                </Text>
              </Box>
            ) : (
              <Box paddingTop="medium" paddingBottom="medium">
                <Text>
                  There are no questions that match your filter. Perhaps you
                  could try a different one or{" "}
                  <Text
                    component="span"
                    color="primary"
                    fauxLink
                    onClick={handleClearFilters}>
                    clear all filters
                  </Text>
                </Text>
              </Box>
            ))}
        </List>
      )}

      {data && data.questions && (
        <CursorPagination {...data.questions.pageInfo} />
      )}

      {questionPreviewOpen && (
        <QuestionPreviewModal
          accountId={accountId}
          queryVariables={variables}
          queryContext="questions"
        />
      )}
    </Flex>
  )
}

export default QuestionsTable
