import styled from "styled-components"
import Button from "shared/components/Button"
import { EditorContent } from "@tiptap/react"
import { libraryGray, libraryBorder } from "mill/utils/colors"
import zIndex from "shared/utils/zIndexLevels"
import { lg } from "mill/utils/breakpoints"

export const Toolbar = styled.div`
  ${props =>
    props.detached &&
    !props.fixed &&
    `
    margin-top: -46px;
    padding-bottom: 2px;
    position: relative;
    z-index: ${zIndex.base + 1};
  `}
  ${props =>
    props.fixed &&
    `
    background: ${libraryGray};
    border-top: 2px solid ${libraryBorder};
    border-bottom: 2px solid ${libraryBorder};
    height: 50px;

    position: fixed;
    top: 50px;
    left: 0px;
    right: 0px;
    z-index: ${zIndex.menu - 1};

    @media ${lg} {
      top: 70px;
    }
  `};
`
export const ToolbarButton = styled(Button)`
  height: 5rem;
`

export const ToolbarIcon = styled.div`
  background: ${props =>
    props.active ? props.theme.colors.primary : libraryGray};
  margin-right: 1px;
  padding: 1rem 1.3rem;
  display: inline-block;
  position: relative;
  cursor: pointer;

  ${props =>
    props.active &&
    `
    color: white`};

  ul {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &:hover {
    cursor: pointer;

    ul {
      width: 210px;
      display: block;
      position: absolute;
      background: ${libraryGray};
      margin-top: -2px;
      border-top: 2px solid ${libraryBorder};
      border-bottom: 2px solid ${libraryBorder};
      top: 46px;
      left: 0;
      font-size: 1.5rem;
      z-index: ${zIndex.menu};

      li {
        color: #535353;
        padding: 0.5rem 1.3rem;
        &:hover {
          cursor: pointer;
          font-weight: bold;
        }

        i {
          padding-right: 0.5rem;
        }
      }
    }
  }
`

export const URLInputContainer = styled.div`
  background: ${libraryGray};
  border-right: 2px solid ${libraryBorder};
  padding: 0.8rem 1.5rem;
  display: inline-block;
  input {
    border: 1px solid ${libraryBorder};
    margin-right: 3px;
    background: white;
  }
`

export const ToolbarInner = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    position: relative;
    top: -2px;
  }
`

export const Error = styled.span`
  display: block;
  color: ${props => props.theme.colors.error};
  font-size: 0.8em;
`

export const Container = styled.div`
  margin-bottom: 0;
  div.ProseMirror {
    background: white;
    padding: 0 2rem 2rem 2rem;
    border: 1px solid ${libraryBorder};
    outline: none;
    overflow-x: auto;
  }

  img {
    display: block;
    user-select: all;
  }

  a img {
    display: inline-block;
  }
`

export const SupportLink = styled.a`
  color: ${props => props.theme.colors.primary};
`

export const Content = styled(EditorContent)`
  outline: none;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  &:hover {
    cursor: text;
  }
`
