import styled from "styled-components"

export const Container = styled.div`
  display: flex;
`

export const Sidebar = styled.div`
  flex: 1;
`

export const Content = styled.div`
  flex: 4;
`

export const Table = styled.table`
  width: 100%;

  th {
    text-align: left;
  }
`
