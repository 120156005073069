import React, { useEffect, useRef, Fragment } from "react"
import styled, { useTheme } from "styled-components"
import * as d3 from "d3"
import { sm, md } from "mill/utils/breakpoints"

const Container = styled.div`
  position: relative;
  text-align: center;
  max-width: 20rem;
  margin: 0 auto; // not sure if this is the right approach...

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  path {
    stroke-width: 10;
    fill: transparent;
    stroke-linejoin: round;
  }

  path.underlay {
    stroke: #e7e7e7;
  }
`

const Innards = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Svg = styled.svg`
  display: inline-block;
  position: relative;
  transform: rotate(90deg);
  width: 100%;
  height: 100%;
`

const Percentage = styled.span`
  margin: 0;
  position: absolute;
  font-size: 3rem;
  font-weight: 600;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  ${props =>
    props.color &&
    props.theme.colors[props.color] &&
    `color: ${props.theme.colors[props.color]};`}

  span {
    font-size: 2rem;
  }

  @media ${sm} {
    font-size: 4rem;
  }

  @media ${md} {
    font-size: 5rem;
  }
`

export const Overlay = styled.path`
  stroke: ${props => props.theme.colors[props.$color]};
`

export const FullCircle = ({ color, statistic }) => {
  return (
    <Container>
      <Innards>
        <Percentage color={color}>{statistic}</Percentage>
        <Svg full viewBox="0 0 100 100" $color={color}>
          <path
            d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50"
            className="underlay"></path>
          <Overlay
            $color={color}
            d="M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50"
            className="overlay"
            strokeDasharray="282.7831115722656"
            strokeDashoffset="0"
          />
        </Svg>
      </Innards>
    </Container>
  )
}

const StatisticCircle = ({ color, percentage, animate, loading }) => {
  const chartContainer = useRef()
  const svgRef = useRef()
  const theme = useTheme()
  const path = "M5,50 A45,45,0 1 1 95,50 A45,45,0 1 1 5,50"
  const hasPercentageValue = !loading && percentage !== null
  const percentageText = loading ? "-" : Math.round(percentage * 100)

  useEffect(() => {
    if (chartContainer.current) {
      const svg = d3.select(svgRef.current)

      // remove elements from previous renders
      svg.selectAll("*").remove()

      // underlay
      const underlay = svg
        .append("path")
        .attr("d", path)
        .attr("class", "underlay")
      const underlayLength = underlay.node().getTotalLength()
      const percentageLength =
        underlayLength - (underlayLength / 100) * percentage * 100

      // overlay
      svg
        .append("path")
        .attr("d", path)
        .attr("class", "overlay")
        .attr("stroke-dasharray", underlayLength)
        .attr("stroke-dashoffset", animate ? underlayLength : percentageLength)
        .style("stroke", theme.colors[color])

      if (animate) {
        svg
          .selectAll(".overlay")
          .transition()
          .duration(800)
          .delay(100)
          .attr("stroke-dashoffset", percentageLength)
      }
    }
  })

  return (
    <Container ref={chartContainer}>
      <Innards>
        <Percentage color={color}>
          {loading ? (
            "-"
          ) : hasPercentageValue ? (
            <Fragment>
              {percentageText}
              <span>%</span>
            </Fragment>
          ) : (
            "N/A"
          )}
        </Percentage>
        <Svg ref={svgRef} viewBox="0 0 100 100"></Svg>
      </Innards>
    </Container>
  )
}

export default StatisticCircle

StatisticCircle.defaultProps = {
  color: "primary"
}
