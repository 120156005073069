import { useLocation, useNavigate } from "react-router-dom"

const useFilters = () => {
  let navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)

  const getParamValue = key => {
    return query.get(key)
  }

  const handleParamChange = (key, value) => {
    query.delete("before")
    query.delete("after")
    query.delete(key)

    if (value === null) {
      navigate(`${location.pathname}?${query.toString()}`)
    } else {
      navigate(`${location.pathname}?${query.toString()}&${key}=${value}`)
    }
  }

  const handleClearFilters = () => {
    navigate(location.pathname)
  }

  return {
    getParamValue,
    handleParamChange,
    handleClearFilters
  }
}

export default useFilters
