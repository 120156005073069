import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import Select from "shared/components/Select"
import { inputFontSizes } from "mill/utils/filterStyles"

const Container = styled.div`
  display: grid;
  grid-template-columns: 7.5rem 1fr;

  label {
    font-size: 1.7rem;
    font-weight: 600;
    display: block;
    color: #30454b;
    line-height: 4rem;
  }

  .select-control__control {
    min-height: 4rem;
  }

  select {
    border: none;
    background: transparent;
    min-width: 20rem;
    color: #535353;
    padding-left: 0;
    padding-right: 0;

    ${inputFontSizes}

    &:focus {
      outline: none;
      border-color: ${props => props.theme.colors.primary};
    }
  }
`

const SortBySelector = ({ options }) => {
  let navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const sortByQuery = query.get("sortBy")
  const defaultSortBy = options.find(o => o.default === true)
  const sortBy = sortByQuery || defaultSortBy?.value || options[0].value

  const handleSort = option => {
    const value = option.value
    query.delete("sortBy")
    navigate(`${location.pathname}?${query.toString()}&sortBy=${value}`)
  }

  const selectedOption = options.find(option => option.value == sortBy)

  return (
    <Container data-qa="sort-by-selector">
      <label htmlFor="sortby">Sort by:</label>
      <Select
        simple
        options={options}
        defaultValue={selectedOption}
        onChange={handleSort}
      />
    </Container>
  )
}

export default SortBySelector
