import styled from "styled-components"

export const FieldGroup = styled.div`
  margin-top: 1.5rem;

  label {
    width: 10rem;
    display: inline-block;
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }

  > div {
    display: inline-block;
    min-width: 30rem;
  }
  
  input {
    width: 100%;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 1rem;
    font-size: 1.4rem;
  }

  span {
    color: ${props => props.theme.colors.error};
  }
`