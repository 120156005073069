import React from "react"
import Box from "shared/components/Box"
import { useLocation } from "react-router-dom"
import Link from "shared/components/Link"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const Item = styled(Link)`
  min-width: 3em;
  min-height: 3em;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgb(233, 236, 238);
  border-radius: 5px;
  color: inherit;
  padding: 0 1.5rem;
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }

  i {
    font-size: 1rem;
    ${props => props.$left && "padding-right: 0.3rem"}
    ${props => props.$right && "padding-left: 0.3rem"}
  }
`

const CursorPagination = ({
  hasPreviousPage,
  hasNextPage,
  startCursor,
  endCursor,
  queryParams = {}
}) => {
  const location = useLocation()
  const { beforeParam = "before", afterParam = "after" } = queryParams

  let query = new URLSearchParams(location.search)
  query.delete(beforeParam)
  query.delete(afterParam)

  const scrollToTop = e => {
    window.scrollTo(0, 0)
  }

  return (
    <Box paddingTop="medium" paddingBottom="medium">
      <Container>
        {hasPreviousPage && (
          <Item
            data-qa="pagination-previous"
            $left
            onClick={scrollToTop}
            to={`${
              location.pathname
            }?${beforeParam}=${startCursor}&${query.toString()}`}>
            <i className="fa fa-chevron-left" /> Previous
          </Item>
        )}
        &nbsp;
        {hasNextPage && (
          <Item
            data-qa="pagination-next"
            $right
            onClick={scrollToTop}
            to={`${
              location.pathname
            }?${afterParam}=${endCursor}&${query.toString()}`}>
            Next <i className="fa fa-chevron-right" />
          </Item>
        )}
      </Container>
    </Box>
  )
}

export default CursorPagination
