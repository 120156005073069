import styled from "styled-components"

export const Card = styled.div`
  border-top: 2px solid #eee;

  width: 100%;
  background: #FFFFFF;
  border-radius: 2px;
  margin-bottom: 2rem;

  p {
    font-size: 1.5rem;
    margin: 1.5rem 0;
    color: #8E9091;
    letter-spacing: 0;
  }
`

export const Container = styled.div`
  padding: 2rem 2rem 2rem 0;
  display: flex;
  justify-content: space-between;

  a {
    color: #25c1a9;
  }
`

export const SplitLeft = styled.div`
  display: flex;
  align-content: center;
`

export const SplitRight = styled.div`
  text-align: right;
  margin-top: 2rem;

  p {
    margin-top: 0;
  }

  i {
    margin-right: 0.5rem;
    color: #25c1a9;
  }
`

export const Arrow = styled.div`
  display: flex;
  align-items: center;

  i {
    color: #25c1a9;
    margin-right: 2rem;
    &:hover {
      cursor: pointer;
    }
  }
`

export const Heading = styled.h2`
  margin-bottom: 0.6rem;
`

export const QuestionPill = styled.div`
  font-size: 10px;
  width: 12rem;
  height: 2rem;
  color: ${props => props.theme.colors.secondary};
  font-family: Raleway;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`

export const CampaignPill = styled(QuestionPill)`
  color: ${props => props.theme.colors.primary};
`


export const Drawer = styled.div`
  background: #FCFCFC;
  color: #8E9091;
  padding: 3rem;
  display: flex;

  h3 {
    font-size: 14px;
    color: #6D6F71;
    text-transform: uppercase;
  }

  p {
    font-size: 15px;
    color: #8E9091;
  }

  i {
    font-weight: 600;
    font-size: 2rem;
  }
`

export const AdditionalFeedbackDetails = styled.div`
  border-right: 1px solid #e9e9e9;
  padding-right: 2rem;
  margin-right: 2rem
`

export const FeedbackComments = styled.div`
  min-width: 16rem;

  textarea {
    font-family: "Open Sans", sans-serif;
  }

  button {
    display: block;
  }
`