import styled from "styled-components"

export const Container = styled.div`
  background: #ffffff;
  box-shadow: 2px 2px 2px 0 rgba(228, 228, 228, 0.4);
  border-radius: 3px;
  margin-bottom: 2rem;
  border-left: 5px solid ${props => props.theme.colors.primary};
  padding: 1rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Text = styled.div`
  flex: 1;
`

export const Value = styled.p`
  font-size: 3.6rem;
  font-weight: bold;
  color: ${props => props.theme.colors.admin};
  flex: 1;
  margin: 0;
`

export const Header = styled.h3`
  font-family: ${props => props.theme.fonts.body};
  color: ${props => props.theme.colors.secondaryHeader};
  font-weight: 500;
  font-size: 1.5rem;
  text-align: right;
  margin: 0;
  padding-right: 2rem;
`

export const Subheader = styled(Header)`
  font-weight: bold;
`