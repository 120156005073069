import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

export const Modal = styled.div`
  max-width: 80vw;
  flex: 1;
  max-height: 90vh;
  position: relative;
  box-shadow: 0px 0px 20px 9px rgb(0 0 0 / 30%);

  i {
    cursor: pointer;
    position: absolute;
    right: -4rem;
    top: -4rem;
    color: white;
    font-size: 3rem;
    z-index: ${zIndex.modal};
    &:hover {
      opacity: 0.5;
    }
  }
`
