import React from "react"
import VideoFrame from "mill/components/VideoFrame"
import { createPortal } from "react-dom"
import usePortal from "mill/hooks/usePortal"
import { ModalContainer } from "mill/utils/shared/core"
import { Modal } from "./styles"

const QuestionVideoModal = ({ toggleModal, modalOpen, videoUrl }) => {
  const target = usePortal("modal-root")
  const modalRef = React.createRef()

  if (!modalOpen) {
    return null
  }

  const handleClickOutside = event => {
    if (
      modalRef &&
      modalRef.current &&
      !modalRef.current.contains(event.target)
    ) {
      toggleModal()
    }
  }

  return createPortal(
    <ModalContainer onClick={handleClickOutside} id="video-modal">
      <Modal ref={modalRef}>
        <i onClick={toggleModal} className="fa fa-cross" />
        <VideoFrame videoUrl={videoUrl} />
      </Modal>
    </ModalContainer>,
    target
  )
}

export default QuestionVideoModal
