import React, { Fragment } from "react"
import styled from "styled-components"
import FilterBar from "shared/components/FilterBar"
import SearchSelector from "shared/containers/SearchSelector"
import TopicSelector from "mill/containers/TopicSelector"
import QuestionCollectionSelector from "mill/containers/QuestionCollectionSelector"
import QuestionPublishedStatusSelector from "mill/containers/QuestionPublishedStatusSelector"
import SortBySelector from "shared/containers/SortBySelector"
import { useFilterStateReducer } from "shared/hooks/useSelector"
import { md, lg, xl } from "mill/utils/breakpoints"
import { InputStyles } from "mill/utils/filterStyles"

const sortOptions = [
  { label: "Alphabetical (A→Z)", value: "questions.question asc" },
  { label: "Alphabetical (Z→A)", value: "questions.question desc" },
  { label: "Newest to oldest", value: "questions.createdAt desc" },
  { label: "Oldest to newest", value: "questions.createdAt asc" }
]

const QuestionFilterGrid = styled.div`
  @media ${md} {
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: 1fr 1fr;

    ${props =>
      props.withStatusFilter &&
      `
      grid-template-columns: 1fr 1fr 33rem;
    `}
  }
`

const QuestionFilterBar = ({
  context = "question",
  withPublishedStatusFilter
}) => {
  const initiallyShowFilterBar = useFilterStateReducer([
    "topicIds",
    "collectionIds",
    "expiredOnly"
  ])
  return (
    <FilterBar
      initiallyOpen={initiallyShowFilterBar}
      visibleComponent={
        <Fragment>
          <SearchSelector autoFocus={true} placeholder={`Find a ${context}`} />
          <SortBySelector options={sortOptions} />
        </Fragment>
      }>
      <InputStyles>
        <QuestionFilterGrid withStatusFilter={withPublishedStatusFilter}>
          <TopicSelector />
          <QuestionCollectionSelector />
          {withPublishedStatusFilter && <QuestionPublishedStatusSelector />}
        </QuestionFilterGrid>
      </InputStyles>
    </FilterBar>
  )
}

export default QuestionFilterBar
