import React, { Fragment } from "react"
import styled, { ThemeProvider } from "styled-components"
import { useQuery } from "@apollo/client"
import Box from "shared/components/Box"
import Button from "shared/components/Button"
import { Well } from "cartwright/utils/sharedStyles"
import PlatformNavigation from "cartwright/components/Platform/PlatformNavigation"
import { Container, Sidebar, Content } from "cartwright/components/Layout"

import FETCH_THEMES from "cartwright/graphql/FetchThemes"

const ThemeContainer = styled.div`
  padding: 1rem 0;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-left: 8px solid ${(props) => props.theme.colors.primary};
  padding: 2rem;
  margin-bottom: 2rem;

  h1 {
    margin-top: 0;
    color: ${(props) => props.theme.colors.primary};
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
  }

  span {
    display: inline-block;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }

  p {
    margin-bottom: 0.5rem;
  }

  li {
    display: inline-block;
    margin-right: 1rem;
  }
`

const Tiles = styled.div`
  display: flex;
`
const Tile = styled.div`
  width: 4rem;
  height: 4rem;
  background: ${(props) => props.color};
`

const PlatformThemes = () => {
  const { data, loading } = useQuery(FETCH_THEMES, {
    fetchPolicy: "network-only",
  })

  if (loading || !data) {
    return null
  }

  const { themes } = data

  return (
    <Fragment>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h1>Themes</h1>
        <Button label="New theme" to="/platform/themes/new" />
      </Box>
      <Container>
        <Sidebar>
          <PlatformNavigation />
        </Sidebar>
        <Content>
          {themes.length === 0 && (
            <Well>
              <h2>No themes</h2>
            </Well>
          )}
          {themes.map((theme) => {
            const themeInUse = theme.usedBy.length > 0
            const themeObject = JSON.parse(theme.threadJson)
            return (
              <ThemeProvider theme={themeObject} key={theme.id}>
                <ThemeContainer>
                  <div>
                    <header>
                      <h1>{theme.name}</h1>
                      <Button
                        color="secondary"
                        label="Edit"
                        to={`/platform/themes/${theme.id}/edit`}
                      />
                    </header>
                    <Tiles>
                      {Object.keys(themeObject.colors).map((k) => {
                        return <Tile key={k} color={themeObject.colors[k]} />
                      })}
                    </Tiles>
                    {themeInUse && (
                      <Fragment>
                        <p>Accounts using theme:</p>
                        <ul>
                          {theme.usedBy.map((account) => (
                            <li key={account.id}>
                              <Button
                                color="primary"
                                size="small"
                                to={`/accounts/${account.id}`}
                                label={account.name}
                              />
                            </li>
                          ))}
                        </ul>
                      </Fragment>
                    )}
                  </div>
                </ThemeContainer>
              </ThemeProvider>
            )
          })}
        </Content>
      </Container>
    </Fragment>
  )
}

export default PlatformThemes
