import React, { useState } from "react"
import { md, lg } from "mill/utils/breakpoints"
import { useLocation, useNavigate } from "react-router-dom"
import Checkbox from "shared/components/Checkbox"
import Box from "shared/components/Box"
import Text from "shared/components/Text"

import styled from "styled-components"

export const CampaignStateCheckboxes = styled.div`
  span {
    margin: 0rem 2rem 0 1rem;
    line-height: 2.4rem;
    cursor: pointer;
  }
  > div {
    display: flex;
  }
  @media ${md} {
    display: flex;
  }
`

const stateOptions = {
  Draft: ["draft"],
  Upcoming: ["scheduled"],
  Active: ["warmup", "active", "cooldown"],
  Completed: ["completed"]
}

const stateOptionKeys = Object.keys(stateOptions)
const allStates = Object.values(stateOptions).flat()

const StateSelector = () => {
  let navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [selectedStates, setSelectedStates] = useState(
    (query.get("states") && query.get("states").split(",")) || allStates
  )

  const stateActive = key => {
    return stateOptions[key].every(v => selectedStates.includes(v))
  }

  const toggleStateSelection = state => {
    const states = stateOptions[state]
    query.delete("states")
    if (stateActive(state)) {
      const newStates = selectedStates.filter(s => !states.includes(s))
      setSelectedStates(newStates)
      navigate(`${location.pathname}?${query.toString()}&states=${newStates}`)
    } else {
      const newStates = [...selectedStates, ...states]
      setSelectedStates(newStates)
      navigate(`${location.pathname}?${query.toString()}&states=${newStates}`)
    }
  }

  return (
    <Box paddingTop="medium">
      <Text as="label" weight="xstrong" tone="dark">
        State
      </Text>
      <CampaignStateCheckboxes>
        {stateOptionKeys.map(state => {
          return (
            <div key={state}>
              <Checkbox
                inline
                solo
                onCheck={() => toggleStateSelection(state)}
                onUnCheck={() => toggleStateSelection(state)}
                checked={stateActive(state)}
              />
              <span onClick={() => toggleStateSelection(state)}>{state}</span>
            </div>
          )
        })}
      </CampaignStateCheckboxes>
    </Box>
  )
}

export default StateSelector
