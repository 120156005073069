import React from "react"
import styled, { css, keyframes } from "styled-components"

// TO DO
// import shared version of this component in the mill

const animation = keyframes`
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }
  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
`

const Container = styled.div`
  height: 100%;
  min-height: 100%;
  width: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Inner = styled.div`
  flex: 1 0 auto;
`

const Spinner = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  margin: 100px auto;
`

const bounce = props => {
  return css`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.primary};
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: ${animation} 2s infinite ease-in-out;
    animation: ${animation} 2s infinite ease-in-out;
  `
}

const Bounce1 = styled.div`
  ${bounce()};
`

const Bounce2 = styled.div`
  ${bounce()} -webkit-animation-delay: -1.0s;
  animation-delay: -1s;
`

const Loader = () => {
  return (
    <Container>
      <Inner>
        <Spinner>
          <Bounce1 />
          <Bounce2 />
        </Spinner>
      </Inner>
    </Container>
  )
}

export default Loader
