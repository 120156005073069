import styled from "styled-components"
import { lg, md } from "mill/utils/breakpoints"

export const Frame = styled.div`
  min-width: 320px;
  max-width: 400px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;

  @media ${md} {
    max-width: 320px;
  }

  @media ${lg} {
    min-width: 400px;
  }
`

export const Header = styled.div`
  background-color: ${props => props.theme.colors.primary};
  span {
    display: block;
    line-height: 5rem;
    text-align: center;
    font-size: 1rem;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
`

export const Question = styled.h2`
  padding: 1.5rem;
  margin: 0;
  font-size: 1.6rem;
  font-weight: 600;
  border-bottom: 1px solid #f1f1f1;
  white-space: pre-line;
`

export const AnswerList = styled.ul`
  margin: 0;
  padding: 0;

  li {
    font-size: 1.3rem;
    padding: 2rem 2.5rem 2rem 7.5rem;
    position: relative;
    font-weight: normal;
    list-style: none;
    border-bottom: 1px solid #f1f1f1;
    margin: 0;

    span {
      display: inline-block;
    }
  }
`

export const ResourceLinkContainer = styled.div`
  background-color: #f1f1f1;
  padding: 1rem 2rem;
  font-size: 0.8em;
  overflow: hidden;

  p {
    margin: 1rem 0;
  }
`
