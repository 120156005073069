import React from "react"
import styled from "styled-components"
import { getDisplayStyles } from "shared/utils/boxSizes"

const sizes = {
  xsmall: "1.2rem",
  msmall: "1.3rem",
  small: "1.4rem",
  medium: "1.6rem",
  large: "1.8rem",
  xlarge: "2rem"
}

const weights = {
  light: "300",
  regular: "400",
  strong: "500",
  mstrong: "600",
  xstrong: "700"
}

const tones = {
  light: "#D4DFE3",
  neutral: "#767676",
  mediumDark: "#7A8381",
  dark: "#535353"
}

const families = {
  heading: "Raleway, serif",
  paragraph: "'Open Sans', sans-serif"
}

const StyledText = styled.p`
  margin: 0;

  ${props => props.family && `font-family: ${families[props.family]};`}
  ${props => props.readableWidth && `max-width: 80rem;`}
  ${props => props.align && getDisplayStyles("text-align", props.align)}
  ${props => props.display && getDisplayStyles("display", props.display)}
  ${props => props.textTransform && `text-transform: ${props.textTransform};`}
  ${props => props.size && `font-size: ${sizes[props.size]};`}
  ${props => props.weight && `font-weight: ${weights[props.weight]};`}
  ${props => props.tone && `color: ${tones[props.tone]};`}
  ${props => props.fauxLink && `&:hover { cursor: pointer; }`}
  ${props => props.centreSelf && `margin: 0 auto;`}
  ${props =>
    props.color &&
    props.theme.colors[props.color] &&
    `color: ${props.theme.colors[props.color]};`}
  ${({ capitalize }) =>
    capitalize &&
    `&:first-letter {
      text-transform: capitalize;
    }`}

  a, a:visited {
    color: ${props => props.theme.colors.primary};
  }
`

const Text = ({ children, ...props }) => {
  return (
    <StyledText as={props.component} {...props}>
      {children}
    </StyledText>
  )
}

export default Text

Text.defaultProps = {
  size: "medium",
  weight: "regular",
  tone: "neutral",
  component: "p",
  family: "paragraph"
}
