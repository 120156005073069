import React from "react"
import Link from "shared/components/Link"
import styled from "styled-components"

const Container = styled(Link)`
  text-decoration: none;
  display: block;
  background: white;
  padding: 1.5rem 2rem;
  color: #535353;
  border-top: 2px solid #eee;
  display: flex;

  &:hover {
    background: #efefef;
    cursor: pointer;
  }

  strong,
  em {
    display: block;
  }
`

const CategoryIndicator = styled.div`
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  max-width: 3rem;
  max-height: 3rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 3rem;
  border-radius: 4px;
  background: ${props =>
    props.category === "standard"
      ? props.theme.colors.primary
      : props.theme.colors.secondary};
`

const QuestionResultCard = ({ question, to }) => {
  return (
    <Container to={to} id={`question-${question.id}`}>
      <CategoryIndicator category={question.category}>
        {question.category === "standard" ? "S" : "P"}
      </CategoryIndicator>
      <div>
        <strong>{question.question}</strong>
        <small>{question.topics.map(t => t.name).join(", ")}</small>
      </div>
    </Container>
  )
}
export default QuestionResultCard
