import React from "react"
import styled, { css, keyframes } from "styled-components"
import { getBoxStyles, getDisplayStyles } from "shared/utils/boxSizes"

const widthSizes = {
  xsmall: "15rem",
  small: "30rem",
  medium: "50rem",
  large: "70rem",
  xlarge: "90rem",
  xxlarge: "110rem"
}

const boxShadows = {
  small: "0px 1px 4px rgba(0, 0, 0, 0.04)"
}

const Box = styled.div`
  ${({ $display }) => $display && getDisplayStyles("display", $display)}
  ${({ position }) => position && getDisplayStyles("position", position)}

  // flex attributes
  ${({ flexDirection }) =>
    flexDirection && getDisplayStyles("flex-direction", flexDirection)}
  ${({ justifyContent }) =>
    justifyContent && getDisplayStyles("justify-content", justifyContent)}
  ${({ justifyItems }) =>
    justifyItems && getDisplayStyles("justify-items", justifyItems)}
  ${({ alignItems }) =>
    alignItems && getDisplayStyles("align-items", alignItems)}
  ${({ flex }) => flex && getDisplayStyles("flex", flex)}
  ${({ flexWrap }) => flexWrap && getDisplayStyles("flex-wrap", flexWrap)}

  // grid attributes
  ${({ gridGap }) => gridGap && getDisplayStyles("grid-gap", gridGap)}
  ${({ gridTemplateRows }) =>
    gridTemplateRows &&
    getDisplayStyles("grid-template-rows", gridTemplateRows)}
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns &&
    getDisplayStyles("grid-template-columns", gridTemplateColumns)}
  ${({ gridColumnGap }) =>
    gridColumnGap && getDisplayStyles("grid-column-gap", gridColumnGap)}

  ${({ textAlign }) => textAlign && getDisplayStyles("text-align", textAlign)}
  ${({ boxShadow }) =>
    boxShadow &&
    boxShadows[boxShadow] &&
    `box-shadow: ${boxShadows[boxShadow]};`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight};`}

  // width
  ${({ maxWidth }) => maxWidth && `max-width: ${widthSizes[maxWidth]};`}
  ${({ width }) => width && `width: ${widthSizes[width]};`}
  ${({ fullWidth }) => fullWidth && `width: 100%;`}

  // padding
  ${({ padding }) => padding && getBoxStyles("padding", padding)}
  ${({ paddingTop }) => paddingTop && getBoxStyles("padding-top", paddingTop)}
  ${({ paddingRight }) =>
    paddingRight && getBoxStyles("padding-right", paddingRight)}
  ${({ paddingBottom }) =>
    paddingBottom && getBoxStyles("padding-bottom", paddingBottom)}
  ${({ paddingLeft }) =>
    paddingLeft && getBoxStyles("padding-left", paddingLeft)}

  // margin
  ${({ margin }) => margin && getBoxStyles("margin", margin)}
  ${({ marginTop }) => marginTop && getBoxStyles("margin-top", marginTop)}
  ${({ marginRight }) =>
    marginRight && getBoxStyles("margin-right", marginRight)}
  ${({ marginBottom }) =>
    marginBottom && getBoxStyles("margin-bottom", marginBottom)}
  ${({ marginLeft }) => marginLeft && getBoxStyles("margin-left", marginLeft)}
  ${({ center }) => center && `margin: 0 auto;`}

  ${({ $overflow }) => $overflow && getDisplayStyles("overflow", $overflow)}

  // Fade
  ${({ $fadeIn }) =>
    $fadeIn &&
    css`
      animation: ${fadeInAnimation} 500ms;
    `}

  // For aiding styling in development
     ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`}
`

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Component = React.forwardRef(
  ({ children, display, overflow, fadeIn, ...props }, ref) => {
    return (
      <Box
        $fadeIn={fadeIn}
        $display={display}
        $overflow={overflow}
        ref={ref}
        {...props}>
        {children}
      </Box>
    )
  }
)

export default Component

Component.defaultProps = {
  display: "block"
}
