import React from "react"
import { createPortal } from "react-dom"
import Box from "shared/components/Box"
import usePortal from "mill/hooks/usePortal"
import { Container, ModalInner, Close } from "./styles"

const Modal = props => {
  const target = usePortal("modal-root")
  const modalRef = React.createRef()

  if (!props.open) {
    return null
  }

  const handleClickOutside = event => {
    if (
      modalRef &&
      modalRef.current &&
      !modalRef.current.contains(event.target) &&
      !document.getElementById("video-modal")
    ) {
      props.closeModal()
    }
  }

  return createPortal(
    <Container
      onMouseDown={handleClickOutside}
      id="modal"
      data-qa={props["data-qa"]}>
      {props.showCloseIcon && (
        <Close
          data-qa="close-modal"
          onClick={props.closeModal}
          className="fa fa-cross"
        />
      )}
      <ModalInner
        ref={modalRef}
        fullWidth={props.fullWidth}
        maxWidth={props.maxWidth}>
        <Box
          $overflow={props.overflow}
          padding={props.noPadding ? "none" : "medium"}>
          {props.children}
        </Box>
      </ModalInner>
    </Container>,
    target
  )
}

Modal.defaultProps = {
  overflow: { mobile: "scroll", desktop: "visible" }
}

export default Modal
