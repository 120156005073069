import styled from "styled-components"
import Box from "shared/components/Box"

export const Status = styled.span`
  color: #b9b9b9;
  border-bottom: 0.3rem solid #f2f7f8;
  padding: 1.2rem 1.8rem;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  -webkit-transition: 0.2s all ease-in-out;
  transition: 0.2s all ease-in-out;
  ${props =>
    props.active &&
    `color: ${props.theme.colors.primary}; 
    border-bottom: 0.3rem solid ${props.theme.colors.primary};
    -webkit-transition: 0.2s all ease-in-out;
    transition: 0.2s all ease;
  `}
`

export const ActionedToggle = styled(Box)`
  color: #B9B9B9;
`

export const Toggle = styled.div`
  position: relative;
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  margin: 0 0.5rem;

  input {
    display: none;
  }

  input:checked + label {
    span:first-of-type {
      margin-left: 0;
    }
    span:nth-of-type(2) {
      right: 0px;
    }
  }

  label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
    margin: 0;
  }

  span {
    inline-block;
    &:first-of-type {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;

      &:before,
      &:after {
        display: block;
        float: left;
        width: 50%;
        height: 25px;
        padding: 0;
        line-height: 25px;
        font-size: 14px;
        color: white;
        font-weight: bold;
      }
      &:before {
        content: "";
        text-transform: uppercase;
        padding-left: 10px;
        background-color: #f9b523;
        color: #fff;
      }
      &:after {
        content: "";
        text-transform: uppercase;
        padding-right: 10px;
        background-color: #bbb;
        color: #fff;
        text-align: right;
      }
    }
    &:nth-of-type(2) {
      display: block;
      width: 15px;
      margin: 5px;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 25px;
      border: 0 solid #bbb;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }
  }
`
