import React from "react"
import styled from "styled-components"
import { green } from "mill/utils/colors"
import image from "./logo.png"

const Pill = styled.span`
  display: block;
  background-image: url(${image});
  background-repeat: no-repeat;
  background-color: ${green};
  background-size: 38px 12px;
  background-position: center;
  border-radius: 3px;
  width: 50px;
  height: 24px;
  cursor: pointer;
  position: relative;
  top: 0px;
  &:hover {
    span {
      display: inline-block;
    }
  }
`

const Tooltip = styled.span`
  display: none;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: 1.2rem;
  padding: 0.4rem;
  left: 0;
  top: 24px;
  width: 160px;
  text-align: center;
`

const YarnoPill = () => {
  return (
    <Pill>
      <Tooltip>Yarno-provided question.</Tooltip>
    </Pill>
  )
}

export default YarnoPill
