import React from "react"
import styled from "styled-components"
import NavLink from "shared/components/NavLink"

const Nav = styled.nav`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  li {
    margin: 0;
    padding: 0;
  }
`

const Link = styled(NavLink)`
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.6rem;
  margin-right: 1.3rem;
  color: #767676;
  
  &.active {
    color: ${props => props.theme.colors.primary};
    text-decoration: underline;
  }
`

const AccountCampaignNavigation = ({ accountId, campaignId }) => {
  return (
    <Nav>
      <ul>
        <li>
          <Link end to={`/accounts/${accountId}/campaigns/${campaignId}`}>
            Overview
          </Link>
        </li>
        <li>
          <Link to={`/accounts/${accountId}/campaigns/${campaignId}/learners`}>
            Learners
          </Link>
        </li>
        <li>
          <Link to={`/accounts/${accountId}/campaigns/${campaignId}/teams`}>
            Teams
          </Link>
        </li>
        <li>
          <Link to={`/accounts/${accountId}/campaigns/${campaignId}/questions`}>
            Questions
          </Link>
        </li>
        <li>
          <Link to={`/accounts/${accountId}/campaigns/${campaignId}/feedback`}>
            Feedback
          </Link>
        </li>
      </ul>
    </Nav>
  )
}

export default AccountCampaignNavigation
