import styled from "styled-components"
import Box from "shared/components/Box"

export const Container = styled(Box)`
  max-height: 30rem;
  max-width: 90rem;
`
export const Line = styled.path`
  fill: none;
  stroke: #25c1a9;
  stroke-width: 3px;
`
export const XAxis = styled.g`
  text {
    fill: #909090;
    font-size: 1.2rem;
  }

  line,
  path {
    fill: none;
    stroke: none;
  }
`
export const YAxis = styled.g`
  text {
    fill: #909090;
    font-size: 1.2rem;
  }
  line {
    stroke: #f0f0f0;
    stroke-width: 2px;
    stroke-dasharray: 4px;
  }

  path.domain {
    display: none;
  }
`
export const TooltipContainer = styled.g`
  > rect:first-of-type {
    opacity: 0;
    width: 1.5rem;
    height: 1.5rem;
    &:hover {
      cursor: pointer;
    }
  }
  rect + g {
    opacity: 0;
  }
  rect:hover + g {
    opacity: 1;
  }
`
