import styled, { css } from "styled-components"
import { boxSizes } from "shared/utils/boxSizes"
import { sm, md } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

export const inputFontSizes = css`
  font-size: 1.6rem;

  @media ${sm} {
    font-size: 1.8rem;
  }
  @media ${md} {
    font-size: 2rem;
  }
`

export const InputStyles = styled.div`
  position: relative;

  input[type="text"] {
    background: #ededed;
    border: none;
    padding: ${boxSizes.mobile.xsmall} ${boxSizes.mobile.small};
    margin-bottom: ${boxSizes.mobile.small};
  }

  label {
    color: #30454b;
    font-size: 1.7rem;
    font-weight: 600;
    cursor: pointer;
  }

  button {
    border: none;
    background: none;
    text-align: left;
    color: #c1c1c1;
    font-weight: 600;
    font-size: 1.6rem;
    padding: 0;
    &:hover {
      cursor: pointer;
    }
  }
`

export const CustomGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;

  > input,
  > span {
    grid-column: span 2;
  }
`

export const Dropdown = styled.div`
  position: absolute;
  left: 0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04);
  background-color: #fbfbfb;
  max-width: 56rem;
  display: none;
  z-index: ${zIndex.base + 1};

  input {
    width: 100%;
  }

  ${props =>
    props.active &&
    `
  display: block;
  `}
`

export const FauxInput = styled.div`
  ${inputFontSizes}
  cursor: pointer;
`

export const ScrollBox = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: 25rem;

  > div {
    margin-right: 1rem;
    line-height: 2.4rem;
    font-size: 1.6rem;
    color: #909090;
    padding: 2px ${boxSizes.mobile.xsmall};
    margin-bottom: 1px;

    &:hover {
      cursor: pointer;
    }
  }

  div[data-selected="true"] {
    border-radius: 3px;
    background: ${props => props.theme.colors.primary};
    color: white;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background: #ededed;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.colors.primary};
    border-radius: 0px;
    -webkit-box-shadow: none;
  }
`
